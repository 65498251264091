import React, { FC } from 'react';
import styled from 'styled-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useResizeDetector } from 'react-resize-detector';
import TextField from '@mui/material/TextField/TextField';

import { H1, H3 } from '../components/atoms/Headers';
import { dWhiteMotorsRed } from '../constants/colors';
import { DWhiteRedButton } from '../components/atoms/Button';
import { parseQueryString } from '../helpers/urlHelper';
import Card from '@mui/material/Card/Card';
import { CardContent } from '@mui/material';

import allInventoryData from '../JSON/generatedData.json';
import { AutoRecord } from '../types/core';
import { IDSpan } from '../components/atoms/Id';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  padding: 1rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
  max-width: 1680px;
`;

const inputSx = {
  width: '75%',
  margin: '15px 0',

  '& .MuiOutlinedInput-input': {
    padding: '10px',
    backgroundColor: 'white',
    borderRadius: '5px',
    border: `2px solid ${dWhiteMotorsRed}`,
  },

  '& .MuiInputBase-multiline': {
    width: '100%',
    padding: 0,
  },
};

const labelSx = {
  '&.MuiInputLabel-root': {
    lineHeight: 1,
  },
};

type FormValues = {
  firstName: string;
  lastName?: string;
  email: string;
  phone: string;
  description: string;
  inventoryId?: string;
};

interface ContactDPageProps {}

export const ContactDPage: FC<ContactDPageProps> = () => {
  const { width, ref } = useResizeDetector();

  const urlQueryString = window.location.search;
  const additionalAttrs = parseQueryString(urlQueryString);

  const inventoryData = allInventoryData.find(
    (invItem) => invItem.id === additionalAttrs.inventoryId
  ) as AutoRecord;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      description: '',
      inventoryId: additionalAttrs.inventoryId || undefined,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => console.log(data);

  return (
    <Container ref={ref}>
      <H1>Contact D!</H1>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.firstName}
          placeholder={
            !!errors.firstName
              ? 'First Name is required to Contact D!'
              : 'First Name'
          }
          helperText={!!errors.firstName && 'This Field is Required'}
          {...register('firstName', { required: true })}
        />
        <TextField
          margin="dense"
          InputLabelProps={{ sx: labelSx }}
          sx={inputSx}
          placeholder={'Last Name'}
          {...register('lastName', { required: false })}
        />
        <TextField
          margin="dense"
          InputLabelProps={{ sx: labelSx }}
          sx={inputSx}
          error={!!errors.email}
          placeholder={
            !!errors.email ? 'Email is required to Contact D!' : 'Email'
          }
          helperText={!!errors.email && 'This Field is Required'}
          {...register('email', { required: true })}
        />
        <TextField
          margin="dense"
          InputLabelProps={{ sx: labelSx }}
          sx={inputSx}
          error={!!errors.phone}
          placeholder={
            !!errors.phone
              ? 'Phone Number is required to Contact D!'
              : 'Phone Number'
          }
          helperText={!!errors.phone && 'This Field is Required'}
          {...register('phone', { required: true })}
        />
        <TextField
          margin="dense"
          InputLabelProps={{ sx: labelSx }}
          sx={inputSx}
          multiline
          rows={4}
          error={!!errors.description}
          placeholder={
            !!errors.description
              ? 'Message is required to contact D!'
              : "Hey D! I'm interested in... I have a question about... Are you available on..."
          }
          helperText={!!errors.description && 'This Field is Required'}
          {...register('description', { required: true })}
        />
        {additionalAttrs.inventoryId && (
          <Card sx={{ width: '75%', margin: '10px 0' }}>
            <CardContent sx={{ paddingBottom: 0 }}>
              <H3
                style={{ margin: '0 0 1rem 0' }}
              >{`${inventoryData.year} ${inventoryData.make} ${inventoryData.model}`}</H3>
              <IDSpan>VIN: {inventoryData.vin}</IDSpan>
            </CardContent>
          </Card>
        )}
        <DWhiteRedButton type="submit">Submit</DWhiteRedButton>
      </Form>
    </Container>
  );
};
