import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { useResizeDetector } from 'react-resize-detector';

import allInventoryData from '../JSON/generatedData.json';
import { H1, H3 } from '../components/atoms/Headers';

import { AutoRecord } from '../types/core';
import { Tabs, TabsProps } from '../components/molecules/Tabs';
import { Tag } from '../components/atoms/Tag';
import Stack from '@mui/material/Stack/Stack';
import { ImageCarousel } from '../components/molecules/ImageCarousel';
import { DWhiteRedButton } from '../components/atoms/Button';
import { PATHS } from '../constants/paths';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  padding: 1rem;
`;

const TabDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HeaderSection = styled.div<{ shouldTransition: boolean }>`
  display: flex;
  flex-direction: ${({ shouldTransition }) =>
    !shouldTransition ? 'column' : 'row'};
  width: 100%;
  align-items: center;
  justify-content: ${({ shouldTransition }) =>
    !shouldTransition ? 'center' : 'space-between'};
  margin-bottom: ${({ shouldTransition }) => (shouldTransition ? '0' : '2rem')};
`;

const TabsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const InfoHeader = styled(H3)`
  margin: 0;
`;

const StyledContactButton = styled(DWhiteRedButton)<{
  shouldTransition: boolean;
}>`
  margin: 0;
  max-width: ${({ shouldTransition }) => (shouldTransition ? '15rem' : '100%')};
`;

const ImageSection = styled.div<{ transition?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: ${({ transition }) => (transition ? '80%' : '95%')};
`;

const TabDataHeader = styled(H3)`
  color: black;
  margin: 1rem 0;
  font-size: 22px;
  text-decoration: underline;

  &:first-child {
    margin-top: 0;
  }
`;

const TabDataContent = styled.div`
  color: black;
  font-size: 16px;
`;

interface InventoryItemPageProps {}

export const InventoryItemPage: FC<InventoryItemPageProps> = () => {
  const [value, setValue] = useState(0);
  const { id: inventoryId } = useParams<{ id: string }>();
  const { width, ref } = useResizeDetector();
  const navigate = useNavigate();

  const inventoryData = allInventoryData.find(
    (invItem) => invItem.id === inventoryId
  ) as AutoRecord;

  const shouldTransition = width! > 675;

  const title = `${inventoryData?.year} ${inventoryData?.make} ${inventoryData?.model}`;
  const description = inventoryData?.description;
  const vehicleStatus = inventoryData?.salesStatus || 'Available';

  const handleContactClick = () => {
    const inventoryId = inventoryData.id;
    navigate(`${PATHS.contact.path}?inventoryId=${inventoryId}`);
  };

  const inventoryTabData: TabsProps['data'] = [
    {
      label: 'Overview',
      content: (
        <TabDataContainer>
          <TabDataHeader>Price</TabDataHeader>
          <TabDataContent>${inventoryData.price}</TabDataContent>
          <TabDataHeader>Status</TabDataHeader>
          <TabDataContent>{vehicleStatus}</TabDataContent>
          <TabDataHeader>Vehicle Type</TabDataHeader>
          <TabDataContent>{inventoryData.vehicleType}</TabDataContent>
          <TabDataHeader>Description</TabDataHeader>
          <TabDataContent>{description}</TabDataContent>
          <TabDataHeader>Tags</TabDataHeader>
          <TabDataContent>
            <Stack direction="row" spacing={1}>
              {inventoryData.tags?.map((tag, i) => (
                <Tag label={tag} key={`${tag}-${i}`} />
              ))}
            </Stack>
          </TabDataContent>
        </TabDataContainer>
      ),
    },
    {
      label: 'Specs',
      content: (
        <TabDataContainer>
          <TabDataHeader>Fuel Type and Mileage</TabDataHeader>
          <TabDataContent>Fuel Type: {inventoryData.fuel}</TabDataContent>
          <TabDataContent>Highway: {inventoryData.mpgHighway}</TabDataContent>
          <TabDataContent>City: {inventoryData.mpgCity}</TabDataContent>
          <TabDataHeader>Drivetrain</TabDataHeader>
          <TabDataContent>{inventoryData.driveTrain}</TabDataContent>
          <TabDataHeader>Engine</TabDataHeader>
          <TabDataContent>{inventoryData.engine}</TabDataContent>
          {inventoryData.bedLength && (
            <>
              <TabDataHeader>Bed Length</TabDataHeader>
              <TabDataContent>{inventoryData.bedLength}</TabDataContent>
            </>
          )}
        </TabDataContainer>
      ),
    },
    {
      label: 'Features',
      content: (
        <TabDataContainer>
          <TabDataHeader>Color</TabDataHeader>
          <TabDataContent>
            Interior: {inventoryData.interiorColor}
          </TabDataContent>
          <TabDataContent>
            Exterior: {inventoryData.exteriorColor}
          </TabDataContent>
          {inventoryData.packages?.length && (
            <>
              <TabDataHeader>Packages</TabDataHeader>
              <TabDataContent>
                {inventoryData.packages?.map((acc, i) => (
                  <Tag key={`${acc}-${i}`} label={acc} />
                ))}
              </TabDataContent>
            </>
          )}
          <TabDataHeader>Accessories</TabDataHeader>
          <TabDataHeader style={{ fontSize: '16px' }}>Exterior</TabDataHeader>
          <TabDataContent>
            {inventoryData.exteriorAccessories?.map((acc, i) => (
              <Tag key={`${acc}-${i}`} label={acc} />
            ))}
          </TabDataContent>
          <TabDataHeader style={{ fontSize: '16px' }}>Interior</TabDataHeader>
          <TabDataContent>
            {inventoryData.interiorAccessories?.map((acc, i) => (
              <Tag key={`${acc}-${i}`} label={acc} />
            ))}
          </TabDataContent>
          <TabDataHeader>Features</TabDataHeader>
          <TabDataHeader style={{ fontSize: '16px' }}>Safety</TabDataHeader>
          <TabDataContent>
            {inventoryData.safetyFeatures?.map((acc, i) => (
              <Tag key={`${acc}-${i}`} label={acc} />
            ))}
          </TabDataContent>
          <TabDataHeader style={{ fontSize: '16px' }}>Tech</TabDataHeader>
          <TabDataContent>
            {inventoryData.techFeatures?.map((acc, i) => (
              <Tag key={`${acc}-${i}`} label={acc} />
            ))}
          </TabDataContent>
          <TabDataHeader style={{ fontSize: '16px' }}>Mechanical</TabDataHeader>
          <TabDataContent>
            {inventoryData.mechanicalFeatures?.map((acc, i) => (
              <Tag key={`${acc}-${i}`} label={acc} />
            ))}
          </TabDataContent>
          <TabDataHeader style={{ fontSize: '16px' }}>Other</TabDataHeader>
          <TabDataContent>
            {inventoryData.otherFeatures?.map((acc, i) => (
              <Tag key={`${acc}-${i}`} label={acc} />
            ))}
          </TabDataContent>
        </TabDataContainer>
      ),
    },
  ];

  return (
    <Container ref={ref}>
      <HeaderSection shouldTransition={shouldTransition}>
        <H1>{title}</H1>
        <StyledContactButton
          onClick={handleContactClick}
          shouldTransition={shouldTransition}
        >
          Contact D!
        </StyledContactButton>
      </HeaderSection>
      <ImageSection transition={!!shouldTransition}>
        <ImageCarousel invName={title} images={inventoryData.images} />
      </ImageSection>
      <TabsSection>
        <Tabs
          name="inventory-tabs"
          data={inventoryTabData}
          onChange={(val) => setValue(val)}
          value={value}
        />
      </TabsSection>
    </Container>
  );
};
