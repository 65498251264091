import { RootState } from './index';
import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';

export interface SiteState {
  siteInformation: Record<string, string>;
  sideBarOpen: { left: boolean };
  pwaSliderOpen: { bottom: boolean };
  isAdmin: boolean;
}

const generateInitialState = (): SiteState => ({
  siteInformation: {},
  sideBarOpen: { left: false },
  pwaSliderOpen: { bottom: false },
  isAdmin: false,
});

// ASYNC THUNKS

// REDUCERS
const siteSlice: Slice = createSlice({
  name: 'site',
  initialState: generateInitialState(),
  reducers: {
    setSiteSetting: (
      state: SiteState,
      action: PayloadAction<Record<string, string>>
    ) => ({
      ...state,
      siteInformation: action.payload,
    }),
    setSideBar: (state: SiteState, action: PayloadAction<boolean>) => ({
      ...state,
      sideBarOpen: { left: action.payload },
    }),
    setPWASliderBar: (state: SiteState, action: PayloadAction<boolean>) => ({
      ...state,
      pwaSliderOpen: { bottom: action.payload },
    }),
    setIsAdmin: (state: SiteState, action: PayloadAction<boolean>) => ({
      ...state,
      isAdmin: action.payload,
    }),
  },
  extraReducers: (builder: any) => {},
});

// SELECTORS
export const siteInformationSelector = (state: RootState) =>
  state.site.siteInformation;
export const sideBarOpenSelector = (state: RootState) => state.site.sideBarOpen;
export const pwaBarOpenSelector = (state: RootState) =>
  state.site.pwaSliderOpen;
export const isAdminSelector = (state: RootState) => state.site.isAdmin;

// EXPORTS
export const siteReducer = siteSlice.reducer;
export const { setSiteSetting, setSideBar, setPWASliderBar, setIsAdmin } =
  siteSlice.actions;
