import React, { useState, FC, useEffect } from 'react';
import { styled } from 'styled-components';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Slider,
  Stack,
  Chip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DWhiteRedButton } from '../atoms/Button';

import {
  updateFilters,
  resetFilters,
  VehicleOptionsType,
} from '../../store/filters';

import { dWhiteMotorsRed } from '../../constants/colors';
import {
  vehiclePackages,
  vehicleTypes,
  vehicleTechFeatures,
  vehicleExteriorAccessories,
  vehicleMechanicalFeatures,
  interiorVehicleAccessories,
  vehicleSafetyFeatures,
  additionalVehicleFeatures,
} from '../../constants/vehicleInfo';
import { AutoRecord } from '../../types/core';
import { useAppDispatch } from '../../store';

const StyledFilterButton = styled(DWhiteRedButton)<{
  shouldTransition?: boolean;
}>`
  border-radius: 3px;
  font-size: 20px;
  width: 20%;
  color: white;

  &:hover {
    color: black;
  }
`;

const sliderSx = {
  color: dWhiteMotorsRed,
};

const accordianSx = {
  width: '90%',
  borderRadius: '5px',
};

const chipSx = {
  '&.MuiChip-filled': {
    backgroundColor: dWhiteMotorsRed,
    color: 'white',
  },
  '&.MuiChip-outlined': {
    backgroundColor: 'white',
    color: 'black',
  },
};

const stackSx = {
  padding: '0 1rem 15px',
  justifyContent: 'center',
};

interface InventoryFilterMenuProps {}

type vehicleTypesSelected = Record<(typeof vehicleTypes)[number], boolean>;
type vehiclePackagesSelected = Record<
  (typeof vehiclePackages)[number],
  boolean
>;
type vehicleOptionsSelected = Record<VehicleOptionsType, boolean>;

export const InventoryFilterMenu: FC<InventoryFilterMenuProps> = () => {
  const dispatch = useAppDispatch();
  const [filterAccordianExpanded, setFilterAccordianExpanded] = useState<
    string | false
  >(false);

  const [priceSliderValue, setPriceSliderValue] = useState<number[]>([
    0, 80000,
  ]);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState<
    Partial<vehicleTypesSelected>
  >({});
  const [selectedVehiclePackages, setSelectedVehiclePackages] = useState<
    Partial<vehiclePackagesSelected>
  >({});
  const [selectedVehicleOptions, setSelectedVehicleOptions] = useState<
    Partial<vehicleOptionsSelected>
  >({});

  const handleFilterApply = () => {
    dispatch(
      updateFilters({
        priceLow: priceSliderValue[0],
        priceHigh: priceSliderValue[1],
        vehicleType: Object.keys(selectedVehicleTypes) as Array<
          AutoRecord['vehicleType']
        >,
        vehicleOptions: Object.keys(
          selectedVehicleOptions
        ) as Array<VehicleOptionsType>,
      })
    );
    setFilterAccordianExpanded(false);
  };

  const handleFilterReset = () => {
    dispatch(resetFilters('RESET_FILTERS'));
    setPriceSliderValue([0, 80000]);
    setSelectedVehicleTypes({});
    setSelectedVehiclePackages({});
    setSelectedVehicleOptions({});
  };

  const handlePriceChange = (event: Event, newValue: number[] | number) => {
    if (newValue instanceof Array) {
      setPriceSliderValue(newValue);
    }
  };

  const handleVehicleTypeClick = (
    vehicleType: (typeof vehicleTypes)[number]
  ) => {
    if (selectedVehicleTypes[vehicleType]) {
      setSelectedVehicleTypes({
        ...selectedVehicleTypes,
        [vehicleType]: false,
      });
    } else {
      setSelectedVehicleTypes({
        ...selectedVehicleTypes,
        [vehicleType]: true,
      });
    }
  };

  const handleVehiclePackageClick = (
    vehiclePackage: (typeof vehiclePackages)[number]
  ) => {
    if (selectedVehiclePackages[vehiclePackage]) {
      setSelectedVehiclePackages({
        ...selectedVehiclePackages,
        [vehiclePackage]: false,
      });
    } else {
      setSelectedVehiclePackages({
        ...selectedVehiclePackages,
        [vehiclePackage]: true,
      });
    }
  };

  const handleVehicleOptionClick = (vehicleOption: VehicleOptionsType) => {
    if (selectedVehicleOptions[vehicleOption]) {
      setSelectedVehicleOptions({
        ...selectedVehicleOptions,
        [vehicleOption]: false,
      });
    } else {
      setSelectedVehicleOptions({
        ...selectedVehicleOptions,
        [vehicleOption]: true,
      });
    }
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setFilterAccordianExpanded(isExpanded ? panel : false);
    };

  return (
    <Accordion
      sx={accordianSx}
      expanded={filterAccordianExpanded === 'mainAccordian'}
      onChange={handleChange('mainAccordian')}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="filters-content"
        id="filters-header"
        sx={{ fontSize: '20px' }}
      >
        Filters
      </AccordionSummary>
      <AccordionDetails>Price Range</AccordionDetails>
      <Box
        sx={{
          width: '75%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '2rem auto 1rem auto',
        }}
      >
        <Slider
          getAriaLabel={() => 'Price Range'}
          value={priceSliderValue}
          onChange={handlePriceChange}
          sx={sliderSx}
          valueLabelDisplay="on"
          // TODO: Detect Personalized Price Range
          defaultValue={[0, 80000]}
          // TODO: Set min and max based on data
          min={0}
          max={80000}
        />
      </Box>
      <AccordionDetails>Vehicle Type/Styles</AccordionDetails>
      <Stack
        direction="row"
        useFlexGap
        flexWrap="wrap"
        spacing={2}
        sx={{ justifyContent: 'center' }}
      >
        {vehicleTypes.map((type, i) => (
          <Chip
            sx={chipSx}
            variant={selectedVehicleTypes[type] ? 'filled' : 'outlined'}
            key={type}
            label={type}
            onClick={() => handleVehicleTypeClick(type)}
          />
        ))}
      </Stack>
      <AccordionDetails>Vehicle Packages</AccordionDetails>
      <Stack
        direction="row"
        useFlexGap
        flexWrap="wrap"
        spacing={2}
        sx={{ justifyContent: 'center' }}
      >
        {vehiclePackages.map((vehiclePackage) => (
          <Chip
            sx={chipSx}
            variant={
              selectedVehiclePackages[vehiclePackage] ? 'filled' : 'outlined'
            }
            key={vehiclePackage}
            label={vehiclePackage}
            onClick={() => handleVehiclePackageClick(vehiclePackage)}
          />
        ))}
      </Stack>
      <AccordionDetails>Vehicle Options</AccordionDetails>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '1rem auto 1rem auto',
        }}
      >
        <Accordion sx={accordianSx}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="filters-content"
            id="filters-header"
          >
            Exterior Accessories
          </AccordionSummary>
          <Stack
            direction="row"
            useFlexGap
            flexWrap="wrap"
            spacing={2}
            sx={stackSx}
          >
            {vehicleExteriorAccessories.map((accessory) => (
              <Chip
                sx={chipSx}
                variant={
                  selectedVehicleOptions[accessory] ? 'filled' : 'outlined'
                }
                onClick={() => handleVehicleOptionClick(accessory)}
                key={accessory}
                label={accessory}
              />
            ))}
          </Stack>
        </Accordion>
        <Accordion sx={accordianSx}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="filters-content"
            id="filters-header"
          >
            Interior Accessories
          </AccordionSummary>
          <Stack
            direction="row"
            useFlexGap
            flexWrap="wrap"
            spacing={2}
            sx={stackSx}
          >
            {interiorVehicleAccessories.map((accessory) => (
              <Chip
                sx={chipSx}
                variant={
                  selectedVehicleOptions[accessory] ? 'filled' : 'outlined'
                }
                onClick={() => handleVehicleOptionClick(accessory)}
                key={accessory}
                label={accessory}
              />
            ))}
          </Stack>
        </Accordion>
        <Accordion sx={accordianSx}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="filters-content"
            id="filters-header"
          >
            Safety Features
          </AccordionSummary>
          <Stack
            direction="row"
            useFlexGap
            flexWrap="wrap"
            spacing={2}
            sx={stackSx}
          >
            {vehicleSafetyFeatures.map((feature) => (
              <Chip
                sx={chipSx}
                variant={
                  selectedVehicleOptions[feature] ? 'filled' : 'outlined'
                }
                onClick={() => handleVehicleOptionClick(feature)}
                key={feature}
                label={feature}
              />
            ))}
          </Stack>
        </Accordion>
        <Accordion sx={accordianSx}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="filters-content"
            id="filters-header"
          >
            Tech Features
          </AccordionSummary>
          <Stack
            direction="row"
            useFlexGap
            flexWrap="wrap"
            spacing={2}
            sx={stackSx}
          >
            {vehicleTechFeatures.map((feature) => (
              <Chip
                sx={chipSx}
                variant={
                  selectedVehicleOptions[feature] ? 'filled' : 'outlined'
                }
                onClick={() => handleVehicleOptionClick(feature)}
                key={feature}
                label={feature}
              />
            ))}
          </Stack>
        </Accordion>
        <Accordion sx={accordianSx}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="filters-content"
            id="filters-header"
          >
            Mechanical Features
          </AccordionSummary>
          <Stack
            direction="row"
            useFlexGap
            flexWrap="wrap"
            spacing={2}
            sx={stackSx}
          >
            {vehicleMechanicalFeatures.map((feature) => (
              <Chip
                sx={chipSx}
                variant={
                  selectedVehicleOptions[feature] ? 'filled' : 'outlined'
                }
                onClick={() => handleVehicleOptionClick(feature)}
                key={feature}
                label={feature}
              />
            ))}
          </Stack>
        </Accordion>
        <Accordion sx={accordianSx}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="filters-content"
            id="filters-header"
          >
            Other Features
          </AccordionSummary>
          <Stack
            direction="row"
            useFlexGap
            flexWrap="wrap"
            spacing={2}
            sx={stackSx}
          >
            {additionalVehicleFeatures.map((feature) => (
              <Chip
                sx={chipSx}
                variant={
                  selectedVehicleOptions[feature] ? 'filled' : 'outlined'
                }
                onClick={() => handleVehicleOptionClick(feature)}
                key={feature}
                label={feature}
              />
            ))}
          </Stack>
        </Accordion>
      </Box>
      <AccordionActions>
        <StyledFilterButton onClick={handleFilterReset}>
          Reset
        </StyledFilterButton>
        <StyledFilterButton onClick={handleFilterApply}>
          Apply
        </StyledFilterButton>
      </AccordionActions>
    </Accordion>
  );
};
