import React, { FC } from 'react';

interface IconProps {
  color?: string;
  size?: string | number;
}

export const DWhiteLogoWithName: FC<IconProps> = ({ color, size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || 400}
    zoomAndPan="magnify"
    viewBox="0 0 300 149.999998"
    height={size ? Number(size) * 0.5 : 200}
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
  >
    <defs>
      <g />
      <clipPath id="61d16a36d5">
        <path
          d="M 109 37 L 284 37 L 284 64.597656 L 109 64.597656 Z M 109 37 "
          clipRule="nonzero"
        />
      </clipPath>
    </defs>
    <rect
      x="-30"
      width="360"
      fill="#ffffff"
      y="-15"
      height="179.999997"
      fillOpacity="1"
    />
    <rect
      x="-30"
      width="360"
      fill="#ffffff"
      y="-15"
      height="179.999997"
      fillOpacity="1"
    />
    <rect
      x="-30"
      width="360"
      fill="#0b0b0b"
      y="-15"
      height="179.999997"
      fillOpacity="1"
    />
    <path
      fill="#e72729"
      d="M 23.574219 40.140625 C 26.585938 39.59375 30.414062 38.769531 34.65625 37.808594 L 16.871094 37.945312 L 47.65625 34.785156 L 50.253906 34.234375 L 32.742188 34.371094 L 65.851562 30.664062 L 67.769531 30.25 L 50.390625 30.386719 L 79.945312 27.777344 C 80.628906 27.640625 81.175781 27.503906 81.863281 27.367188 L 65.167969 27.089844 L 88.839844 25.304688 L 79.808594 24.480469 L 108.132812 23.242188 C 144.800781 18.847656 184.207031 20.359375 210.613281 41.105469 C 206.917969 39.316406 203.363281 37.53125 199.667969 36.160156 L 169.019531 36.433594 L 195.699219 34.648438 L 162.585938 33.546875 L 186.667969 31.761719 L 150.273438 31.074219 L 175.449219 29.152344 C 175.039062 29.015625 174.628906 29.015625 174.21875 28.875 L 141.242188 28.601562 L 161.628906 27.089844 C 141.242188 25.167969 120.445312 27.226562 99.511719 31.351562 C 102.113281 38.355469 108.816406 43.164062 120.035156 45.636719 C 87.472656 47.5625 59.832031 36.160156 23.574219 40.140625 Z M 23.574219 40.140625 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <g clipPath="url(#61d16a36d5)">
      <path
        fill="#e72729"
        d="M 240.578125 51.957031 L 227.03125 54.292969 L 245.230469 54.15625 C 246.324219 54.84375 247.28125 55.53125 248.238281 56.214844 L 220.738281 59.652344 L 251.25 59.375 C 251.933594 60.199219 252.34375 61.023438 252.890625 61.988281 C 252.207031 57.179688 251.523438 56.214844 249.472656 53.882812 C 260.691406 55.53125 271.910156 59.238281 283.128906 64.597656 C 250.703125 30.386719 163 51.410156 109.910156 37.808594 C 112.101562 41.105469 118.394531 43.988281 127.289062 46.460938 L 109.089844 47.699219 L 132.210938 47.699219 C 133.71875 48.109375 135.359375 48.386719 137 48.796875 L 122.773438 50.445312 L 146.167969 50.582031 C 146.851562 50.722656 147.675781 50.859375 148.359375 50.996094 L 135.359375 52.917969 L 158.621094 52.644531 C 159.441406 52.78125 160.398438 52.917969 161.355469 53.058594 L 147.675781 54.707031 L 171.753906 54.429688 C 184.207031 56.078125 197.339844 57.453125 209.929688 58.550781 C 214.035156 53.332031 221.695312 49.757812 230.453125 49.757812 C 234.148438 50.308594 237.566406 50.996094 240.578125 51.957031 Z M 240.578125 51.957031 "
        fillOpacity="1"
        fillRule="evenodd"
      />
    </g>
    <path
      fill="#e72729"
      d="M 53.949219 49.210938 L 25.351562 51.132812 L 50.9375 51.546875 C 48.066406 54.15625 46.289062 57.453125 45.875 61.023438 C 49.707031 54.566406 58.875 50.035156 69.546875 50.035156 C 80.21875 50.035156 89.386719 54.566406 93.21875 61.023438 C 92.125 51.957031 82 44.949219 69.546875 44.949219 C 67.085938 44.949219 64.621094 45.226562 62.296875 45.773438 L 38.078125 47.425781 L 55.316406 48.386719 C 54.769531 48.660156 54.359375 48.933594 53.949219 49.210938 Z M 53.949219 49.210938 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <g fill="#ffffff" fillOpacity="1">
      <g transform="translate(13.747029, 122.003992)">
        <g>
          <path d="M 11.109375 -38.140625 C 14.085938 -38.140625 16.273438 -37.296875 17.671875 -35.609375 C 19.078125 -33.921875 19.78125 -31.601562 19.78125 -28.65625 L 19.78125 -9.421875 C 19.78125 -6.484375 19.078125 -4.175781 17.671875 -2.5 C 16.273438 -0.832031 14.085938 0 11.109375 0 L 2.171875 0 L 2.171875 -38.140625 Z M 11.0625 -3.875 C 12.582031 -3.875 13.710938 -4.332031 14.453125 -5.25 C 15.203125 -6.175781 15.578125 -7.515625 15.578125 -9.265625 L 15.578125 -28.875 C 15.578125 -30.613281 15.203125 -31.945312 14.453125 -32.875 C 13.710938 -33.800781 12.5625 -34.265625 11 -34.265625 L 6.421875 -34.265625 L 6.421875 -3.875 Z M 11.0625 -3.875 " />
        </g>
      </g>
    </g>
    <g fill="#ffffff" fillOpacity="1">
      <g transform="translate(35.320163, 122.003992)">
        <g />
      </g>
    </g>
    <g fill="#ffffff" fillOpacity="1">
      <g transform="translate(43.764195, 122.003992)">
        <g>
          <path d="M 12.3125 0 L 6.484375 0 L 1.25 -38.140625 L 5.390625 -38.140625 L 9.859375 -5.671875 L 14.109375 -38.078125 L 18.3125 -38.078125 L 22.5 -5.609375 L 26.8125 -38.140625 L 30.515625 -38.140625 L 25.328125 0 L 19.71875 0 L 16.078125 -25.875 Z M 12.3125 0 " />
        </g>
      </g>
    </g>
    <g fill="#ffffff" fillOpacity="1">
      <g transform="translate(75.524636, 122.003992)">
        <g>
          <path d="M 6.421875 -17.15625 L 6.421875 0 L 2.171875 0 L 2.171875 -38.140625 L 6.421875 -38.140625 L 6.421875 -20.96875 L 15.796875 -20.96875 L 15.796875 -38.140625 L 20.046875 -38.140625 L 20.046875 0 L 15.796875 0 L 15.796875 -17.15625 Z M 6.421875 -17.15625 " />
        </g>
      </g>
    </g>
    <g fill="#ffffff" fillOpacity="1">
      <g transform="translate(97.751506, 122.003992)">
        <g>
          <path d="M 6.421875 -38.140625 L 6.421875 0 L 2.171875 0 L 2.171875 -38.140625 Z M 6.421875 -38.140625 " />
        </g>
      </g>
    </g>
    <g fill="#ffffff" fillOpacity="1">
      <g transform="translate(106.358967, 122.003992)">
        <g>
          <path d="M 7.6875 0 L 7.6875 -34.265625 L 0.65625 -34.265625 L 0.65625 -38.140625 L 18.90625 -38.140625 L 18.90625 -34.265625 L 11.875 -34.265625 L 11.875 0 Z M 7.6875 0 " />
        </g>
      </g>
    </g>
    <g fill="#ffffff" fillOpacity="1">
      <g transform="translate(125.970898, 122.003992)">
        <g>
          <path d="M 15.75 -21.25 L 15.75 -17.4375 L 6.421875 -17.4375 L 6.421875 -3.875 L 17.875 -3.875 L 17.875 0 L 2.171875 0 L 2.171875 -38.140625 L 17.875 -38.140625 L 17.875 -34.265625 L 6.421875 -34.265625 L 6.421875 -21.25 Z M 15.75 -21.25 " />
        </g>
      </g>
    </g>
    <g fill="#ffffff" fillOpacity="1">
      <g transform="translate(145.582829, 122.003992)">
        <g />
      </g>
    </g>
    <g fill="#ffffff" fillOpacity="1">
      <g transform="translate(154.026861, 122.003992)">
        <g>
          <path d="M 16.234375 -0.046875 L 12.15625 -0.046875 L 5.828125 -30.40625 L 5.828125 0 L 2.125 0 L 2.125 -38.140625 L 8.0625 -38.140625 L 14.328125 -7.6875 L 20.375 -38.140625 L 26.3125 -38.140625 L 26.3125 0 L 22.28125 0 L 22.28125 -30.671875 Z M 16.234375 -0.046875 " />
        </g>
      </g>
    </g>
    <g fill="#ffffff" fillOpacity="1">
      <g transform="translate(182.518647, 122.003992)">
        <g>
          <path d="M 1.859375 -28.9375 C 1.859375 -31.875 2.570312 -34.203125 4 -35.921875 C 5.4375 -37.648438 7.625 -38.515625 10.5625 -38.515625 C 13.507812 -38.515625 15.707031 -37.648438 17.15625 -35.921875 C 18.613281 -34.203125 19.34375 -31.875 19.34375 -28.9375 L 19.34375 -9.203125 C 19.34375 -6.296875 18.613281 -3.972656 17.15625 -2.234375 C 15.707031 -0.492188 13.507812 0.375 10.5625 0.375 C 7.625 0.375 5.4375 -0.492188 4 -2.234375 C 2.570312 -3.972656 1.859375 -6.296875 1.859375 -9.203125 Z M 6.046875 -8.984375 C 6.046875 -7.242188 6.414062 -5.890625 7.15625 -4.921875 C 7.90625 -3.960938 9.039062 -3.484375 10.5625 -3.484375 C 12.125 -3.484375 13.269531 -3.960938 14 -4.921875 C 14.726562 -5.890625 15.09375 -7.242188 15.09375 -8.984375 L 15.09375 -29.15625 C 15.09375 -30.894531 14.726562 -32.242188 14 -33.203125 C 13.269531 -34.171875 12.125 -34.65625 10.5625 -34.65625 C 9.039062 -34.65625 7.90625 -34.171875 7.15625 -33.203125 C 6.414062 -32.242188 6.046875 -30.894531 6.046875 -29.15625 Z M 6.046875 -8.984375 " />
        </g>
      </g>
    </g>
    <g fill="#ffffff" fillOpacity="1">
      <g transform="translate(203.710442, 122.003992)">
        <g>
          <path d="M 7.6875 0 L 7.6875 -34.265625 L 0.65625 -34.265625 L 0.65625 -38.140625 L 18.90625 -38.140625 L 18.90625 -34.265625 L 11.875 -34.265625 L 11.875 0 Z M 7.6875 0 " />
        </g>
      </g>
    </g>
    <g fill="#ffffff" fillOpacity="1">
      <g transform="translate(223.322373, 122.003992)">
        <g>
          <path d="M 1.859375 -28.9375 C 1.859375 -31.875 2.570312 -34.203125 4 -35.921875 C 5.4375 -37.648438 7.625 -38.515625 10.5625 -38.515625 C 13.507812 -38.515625 15.707031 -37.648438 17.15625 -35.921875 C 18.613281 -34.203125 19.34375 -31.875 19.34375 -28.9375 L 19.34375 -9.203125 C 19.34375 -6.296875 18.613281 -3.972656 17.15625 -2.234375 C 15.707031 -0.492188 13.507812 0.375 10.5625 0.375 C 7.625 0.375 5.4375 -0.492188 4 -2.234375 C 2.570312 -3.972656 1.859375 -6.296875 1.859375 -9.203125 Z M 6.046875 -8.984375 C 6.046875 -7.242188 6.414062 -5.890625 7.15625 -4.921875 C 7.90625 -3.960938 9.039062 -3.484375 10.5625 -3.484375 C 12.125 -3.484375 13.269531 -3.960938 14 -4.921875 C 14.726562 -5.890625 15.09375 -7.242188 15.09375 -8.984375 L 15.09375 -29.15625 C 15.09375 -30.894531 14.726562 -32.242188 14 -33.203125 C 13.269531 -34.171875 12.125 -34.65625 10.5625 -34.65625 C 9.039062 -34.65625 7.90625 -34.171875 7.15625 -33.203125 C 6.414062 -32.242188 6.046875 -30.894531 6.046875 -29.15625 Z M 6.046875 -8.984375 " />
        </g>
      </g>
    </g>
    <g fill="#ffffff" fillOpacity="1">
      <g transform="translate(244.514167, 122.003992)">
        <g>
          <path d="M 10.671875 -38.140625 C 13.691406 -38.140625 15.863281 -37.394531 17.1875 -35.90625 C 18.507812 -34.414062 19.171875 -32.253906 19.171875 -29.421875 L 19.171875 -26.3125 C 19.171875 -24.207031 18.789062 -22.5 18.03125 -21.1875 C 17.269531 -19.882812 16.070312 -18.976562 14.4375 -18.46875 C 16.175781 -17.957031 17.410156 -17.019531 18.140625 -15.65625 C 18.867188 -14.300781 19.234375 -12.585938 19.234375 -10.515625 L 19.234375 -4.578125 C 19.234375 -3.742188 19.269531 -2.9375 19.34375 -2.15625 C 19.414062 -1.375 19.632812 -0.65625 20 0 L 15.6875 0 C 15.4375 -0.539062 15.253906 -1.128906 15.140625 -1.765625 C 15.035156 -2.398438 14.984375 -3.351562 14.984375 -4.625 L 14.984375 -10.625 C 14.984375 -12.769531 14.5 -14.25 13.53125 -15.0625 C 12.570312 -15.882812 11.203125 -16.296875 9.421875 -16.296875 L 6.421875 -16.296875 L 6.421875 0 L 2.171875 0 L 2.171875 -38.140625 Z M 9.59375 -20.15625 C 11.300781 -20.15625 12.625 -20.507812 13.5625 -21.21875 C 14.507812 -21.925781 14.984375 -23.316406 14.984375 -25.390625 L 14.984375 -29.046875 C 14.984375 -30.785156 14.644531 -32.085938 13.96875 -32.953125 C 13.300781 -33.828125 12.164062 -34.265625 10.5625 -34.265625 L 6.421875 -34.265625 L 6.421875 -20.15625 Z M 9.59375 -20.15625 " />
        </g>
      </g>
    </g>
    <g fill="#ffffff" fillOpacity="1">
      <g transform="translate(265.978337, 122.003992)">
        <g>
          <path d="M 10.1875 -38.515625 C 13.09375 -38.515625 15.234375 -37.648438 16.609375 -35.921875 C 17.992188 -34.203125 18.6875 -31.890625 18.6875 -28.984375 L 18.6875 -27.953125 L 14.65625 -27.953125 L 14.65625 -29.203125 C 14.65625 -30.941406 14.304688 -32.285156 13.609375 -33.234375 C 12.921875 -34.179688 11.816406 -34.65625 10.296875 -34.65625 C 8.773438 -34.65625 7.664062 -34.179688 6.96875 -33.234375 C 6.28125 -32.285156 5.9375 -30.960938 5.9375 -29.265625 C 5.9375 -27.847656 6.253906 -26.609375 6.890625 -25.546875 C 7.523438 -24.492188 8.3125 -23.519531 9.25 -22.625 C 10.195312 -21.738281 11.222656 -20.867188 12.328125 -20.015625 C 13.441406 -19.171875 14.46875 -18.238281 15.40625 -17.21875 C 16.351562 -16.195312 17.144531 -15.039062 17.78125 -13.75 C 18.425781 -12.46875 18.75 -10.9375 18.75 -9.15625 C 18.75 -6.25 18.046875 -3.929688 16.640625 -2.203125 C 15.242188 -0.484375 13.078125 0.375 10.140625 0.375 C 7.191406 0.375 5.023438 -0.484375 3.640625 -2.203125 C 2.265625 -3.929688 1.578125 -6.25 1.578125 -9.15625 L 1.578125 -11.328125 L 5.5625 -11.328125 L 5.5625 -8.9375 C 5.5625 -7.1875 5.921875 -5.835938 6.640625 -4.890625 C 7.367188 -3.953125 8.5 -3.484375 10.03125 -3.484375 C 11.550781 -3.484375 12.675781 -3.953125 13.40625 -4.890625 C 14.132812 -5.835938 14.5 -7.1875 14.5 -8.9375 C 14.5 -10.351562 14.175781 -11.578125 13.53125 -12.609375 C 12.894531 -13.648438 12.101562 -14.613281 11.15625 -15.5 C 10.21875 -16.382812 9.203125 -17.253906 8.109375 -18.109375 C 7.023438 -18.960938 6.007812 -19.898438 5.0625 -20.921875 C 4.125 -21.941406 3.335938 -23.09375 2.703125 -24.375 C 2.066406 -25.664062 1.75 -27.203125 1.75 -28.984375 C 1.75 -31.929688 2.4375 -34.253906 3.8125 -35.953125 C 5.195312 -37.660156 7.320312 -38.515625 10.1875 -38.515625 Z M 10.1875 -38.515625 " />
        </g>
      </g>
    </g>
  </svg>
);
