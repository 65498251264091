import React, { FC } from 'react';
import styled from 'styled-components';
import { ProfileFavoriteCard } from './ProfileFavoriteCard';
import { H2 } from '../atoms/Headers';

import autoData from '../../JSON/generatedData.json';
import { dWhiteMotorsDarkerRed, softWhite } from '../../constants/colors';
import { AutoRecord } from '../../types/core';

const Wrapper = styled.div`
  margin-top: 1rem;
`;

const Title = styled(H2)`
  margin: 0;
  font-size: 2rem;
`;

const Container = styled.div<{ disableBackground: boolean }>`
  border: ${({ disableBackground }) =>
    disableBackground ? 'none' : `2px solid ${dWhiteMotorsDarkerRed}`};
  border-radius: 10px;
  background-color: ${({ disableBackground }) =>
    disableBackground ? 'none' : softWhite};
`;

const ProfileFavoriteCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 600px;
  overflow-y: auto;
  padding: 0 15px 0 0;
  scrollbar-color: ${dWhiteMotorsDarkerRed} ${softWhite};
`;

const NoDocsFoundSpan = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: black;
`;

interface FavoritesSectionProps {}

export const FavoritesSection: FC<FavoritesSectionProps> = () => {
  const favorites: Array<AutoRecord> = (autoData as Array<AutoRecord>).slice(
    0,
    6
  );

  return (
    <Wrapper>
      <Title>Favorites</Title>
      <Container disableBackground={!!favorites.length}>
        {favorites.length ? (
          <ProfileFavoriteCardContainer>
            {favorites.map((item, ix) => (
              <ProfileFavoriteCard item={item} key={ix} />
            ))}
          </ProfileFavoriteCardContainer>
        ) : (
          <NoDocsFoundSpan>No Favorites Found</NoDocsFoundSpan>
        )}
      </Container>
    </Wrapper>
  );
};
