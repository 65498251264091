import React, { FC, useEffect, useState } from 'react';
import { ButtonGroup, Button, Stack } from '@mui/material';
import {
  calculateFinanceTier,
  financeTiers,
  tierType,
} from '../../helpers/financeCalculator';
import { dWhiteMotorsRed } from '../../constants/colors';

interface CreditScoreButtonsProps {
  rate: number;
}

const buttonGroupSx = {};

const buttonSx = (active: boolean, isRate?: boolean) => ({
  width: '20%',
  fontSize: isRate ? '14px' : '10px',
  padding: '10px 5px',
  border: 'none',
  fontWeight: 600,

  ...(active && {
    '&:disabled': {
      backgroundColor: dWhiteMotorsRed,
      color: 'white',
    },
  }),

  ...(!active && {
    '&:disabled': {
      backgroundColor: 'white',
      color: 'black',
    },
  }),
});

export const CreditScoreButtonsWithRates: FC<CreditScoreButtonsProps> = ({
  rate,
}) => {
  const [activeTier, setActiveTier] = useState<tierType>();

  useEffect(() => {
    const tier = calculateFinanceTier(rate);
    setActiveTier(tier);
  }, [rate]);

  return (
    <Stack direction="column">
      <ButtonGroup
        sx={buttonGroupSx}
        variant="outlined"
        aria-label="Credit Score Buttons"
      >
        <Button
          disabled
          sx={buttonSx(activeTier?.label === financeTiers.t0.label)}
        >
          {financeTiers.t0.label}
        </Button>
        <Button
          disabled
          sx={buttonSx(activeTier?.label === financeTiers.t1.label)}
        >
          {financeTiers.t1.label}
        </Button>
        <Button
          disabled
          sx={buttonSx(activeTier?.label === financeTiers.t2.label)}
        >
          {financeTiers.t2.label}
        </Button>
        <Button
          disabled
          sx={buttonSx(activeTier?.label === financeTiers.t3.label)}
        >
          {financeTiers.t3.label}
        </Button>
        <Button
          disabled
          sx={buttonSx(activeTier?.label === financeTiers.t4.label)}
        >
          {financeTiers.t4.label}
        </Button>
      </ButtonGroup>
      <ButtonGroup
        sx={buttonGroupSx}
        variant="outlined"
        aria-label="Credit Score Buttons"
      >
        <Button
          disabled
          sx={buttonSx(activeTier?.rate === financeTiers.t0.rate, true)}
        >
          {financeTiers.t0.rate}%
        </Button>
        <Button
          disabled
          sx={buttonSx(activeTier?.rate === financeTiers.t1.rate, true)}
        >
          {financeTiers.t1.rate}%
        </Button>
        <Button
          disabled
          sx={buttonSx(activeTier?.rate === financeTiers.t2.rate, true)}
        >
          {financeTiers.t2.rate}%
        </Button>
        <Button
          disabled
          sx={buttonSx(activeTier?.rate === financeTiers.t3.rate, true)}
        >
          {financeTiers.t3.rate}%
        </Button>
        <Button
          disabled
          sx={buttonSx(activeTier?.rate === financeTiers.t4.rate, true)}
        >
          {financeTiers.t4.rate}%
        </Button>
      </ButtonGroup>
    </Stack>
  );
};
