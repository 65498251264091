import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div``;

interface LeadByIdPageProps {}

export const LeadByIdPage: FC<LeadByIdPageProps> = () => {
  return <Container>LeadByIdPage</Container>;
};
