import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div``;

interface UserByIdPageProps {}

export const UserByIdPage: FC<UserByIdPageProps> = () => {
  return <Container>UserByIdPage</Container>;
};
