import React, { FC } from 'react';
import { Route, Routes } from 'react-router';
import styled from 'styled-components';
import { useAuthInfo } from '@propelauth/react';

import { useAppDispatch } from './store';
import { setIsAdmin } from './store/site';
import { setPropelAutoUserInfo } from './store/account';

import LandingPage from './pages/LandingPage';
import { InventoryPage } from './pages/InventoryPage';
import { InventoryItemPage } from './pages/InventoryItemPage';
import { MyFavoritesPage } from './pages/MyFavoritesPage';
import { ContactDPage } from './pages/ContactDPage';
import { Reviews } from './pages/Reviews';
import { PlaygroundPage } from './pages/PlaygroundPage';
import { LeadsPage } from './pages/LeadsPage';
import { LeadByIdPage } from './pages/LeadByIdPage';
import { FinanceCalcPage } from './pages/FinanceCalcPage';
import { AdminStatsPage } from './pages/AdminStatsPage';
import { UserManagementPage } from './pages/UserManagementPage';
import { UserByIdPage } from './pages/UserByIdPage';
import { ProfilePage } from './pages/ProfilePage';

import GlobalHeader from './components/organisms/GlobalHeader';

import { PATHS } from './constants/paths';
import { InternalAdminRoute } from './helpers/routeWrapper';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const App: FC = () => {
  const dispatch = useAppDispatch();
  const userSession = useAuthInfo();
  const userData = userSession?.user;

  if (userData) {
    dispatch(setPropelAutoUserInfo(userData));
  }

  const userOrgs = userSession?.orgHelper?.getOrgs();
  if (userOrgs?.length) {
    const isAdmin = userOrgs.find((org) => org.orgName === 'Admin');
    if (isAdmin) {
      dispatch(setIsAdmin(true));
    }
  }

  return (
    <Container>
      <GlobalHeader />
      <Routes>
        {/* Public Routes */}
        <Route path={PATHS.landing.path} element={<LandingPage />} />
        <Route path={PATHS.inventory.path} element={<InventoryPage />} />
        <Route
          path={PATHS.inventoryItem.path}
          element={<InventoryItemPage />}
        />
        <Route path={PATHS.favorites.path} element={<MyFavoritesPage />} />
        <Route path={PATHS.profile.path} element={<ProfilePage />} />
        <Route path={PATHS.contact.path} element={<ContactDPage />} />
        <Route path={PATHS.reviews.path} element={<Reviews />} />

        {/* Admin Routes */}
        <Route path={PATHS.playground.path} element={<InternalAdminRoute />}>
          <Route path={PATHS.playground.path} element={<PlaygroundPage />} />
        </Route>
        <Route path={PATHS.leads.path} element={<InternalAdminRoute />}>
          <Route path={PATHS.leads.path} element={<LeadsPage />} />
        </Route>
        <Route path={PATHS.lead.path} element={<InternalAdminRoute />}>
          <Route path={PATHS.lead.path} element={<LeadByIdPage />} />
        </Route>
        <Route path={PATHS.financing.path} element={<InternalAdminRoute />}>
          <Route path={PATHS.financing.path} element={<FinanceCalcPage />} />
        </Route>
        <Route path={PATHS.stats.path} element={<InternalAdminRoute />}>
          <Route path={PATHS.stats.path} element={<AdminStatsPage />} />
        </Route>
        <Route path={PATHS.users.path} element={<InternalAdminRoute />}>
          <Route path={PATHS.users.path} element={<UserManagementPage />} />
        </Route>
        <Route path={PATHS.user.path} element={<InternalAdminRoute />}>
          <Route path={PATHS.user.path} element={<UserByIdPage />} />
        </Route>
      </Routes>
    </Container>
  );
};

export default App;
