import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div``;

interface ReviewsProps {}

export const Reviews: FC<ReviewsProps> = () => {
  return <Container>Reviews</Container>;
};
