import React, { FC } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import {
  useRedirectFunctions,
  useAuthInfo,
  useLogoutFunction,
} from '@propelauth/react';
import { IconButton, SwipeableDrawer, SxProps, Theme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useResizeDetector } from 'react-resize-detector';

import { LeftDrawerMenu } from './LeftDrawerMenu';

import { Button } from '../atoms/Button';
import { DWhiteLogoWithName } from '../atoms/logos/DWhiteLogoWithName';
import { PATHS } from '../../constants/paths';
import { softWhite } from '../../constants/colors';
import { useAppDispatch } from '../../store';
import { setIsAdmin, setSideBar, sideBarOpenSelector } from '../../store/site';
import { useSelector } from 'react-redux';
import { setPropelAutoUserInfo } from '../../store/account';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  box-shadow: 0px -5px 20px rgba(255, 255, 255, 0.4);
`;

const AuthButton = styled(Button)`
  padding: 10px 20px;
  color: white;
  font-weight: 600;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
  margin-right: 10px;
  text-align: center;

  :hover {
    ${({ disabled }) =>
      !disabled &&
      `
      transform: scale(1.04, 1.02);
      box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.4);
      `}
  }
`;

const LogoContainer = styled.div`
  display: flex;
`;

const GlobalHeader: FC = () => {
  const navigate = useNavigate();
  const logoutFn = useLogoutFunction();
  const dispatch = useAppDispatch();
  const { width, ref } = useResizeDetector();
  const userInfo = useAuthInfo();
  const { redirectToLoginPage } = useRedirectFunctions();

  const drawerMenuOpen = useSelector(sideBarOpenSelector);

  const handleHomeClick = () => {
    if (window.location.pathname === PATHS.landing.path) {
      return;
    }

    navigate(PATHS.landing.path);
  };

  const handleToggleDrawer =
    (anchor: string, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      dispatch(setSideBar(open));
    };

  const handleAuthClick = () => {
    if (userInfo?.isLoggedIn) {
      logoutFn(true);
      dispatch(setPropelAutoUserInfo(null));
      dispatch(setIsAdmin(false));
    } else {
      redirectToLoginPage();
      window.localStorage.clear();
    }
  };

  const iconSX: SxProps<Theme> = { color: softWhite };

  const drawerStyle: SxProps<Theme> = {
    '& .MuiDrawer-paper': {
      maxWidth: '25rem',
      width: '80%',
    },
  };

  const shouldTransition = width && width <= 500;

  return (
    <>
      <SwipeableDrawer
        sx={drawerStyle}
        anchor={'left'}
        transitionDuration={{ enter: 300, exit: 250 }}
        open={drawerMenuOpen['left']}
        onClose={handleToggleDrawer('left', false)}
        onOpen={handleToggleDrawer('left', true)}
      >
        <LeftDrawerMenu />
      </SwipeableDrawer>
      <Container id="globalHeader" ref={ref}>
        <IconButton
          onClick={handleToggleDrawer('left', !drawerMenuOpen['left'])}
          sx={{ marginLeft: '1rem' }}
        >
          <MenuIcon sx={iconSX} />
        </IconButton>
        <LogoContainer onClick={handleHomeClick}>
          <DWhiteLogoWithName size={140} />
        </LogoContainer>
        {shouldTransition ? (
          <IconButton onClick={handleAuthClick} sx={{ marginRight: '1rem' }}>
            <LogoutIcon sx={iconSX} />
          </IconButton>
        ) : (
          <AuthButton onClick={handleAuthClick}>
            {userInfo?.isLoggedIn ? 'Logout' : 'Login'}
          </AuthButton>
        )}
      </Container>
    </>
  );
};

export default GlobalHeader;
