import React, { FC, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { useResizeDetector } from 'react-resize-detector';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Pagination from '@mui/material/Pagination/Pagination';
import PaginationItem, {
  PaginationItemProps,
} from '@mui/material/PaginationItem/PaginationItem';

import { InventoryFilterMenu } from '../components/organisms/InventoryFilterMenu';
import { InventoryCard } from '../components/molecules/InventoryCard';
import { H1 } from '../components/atoms/Headers';

import { filterStateSelector } from '../store/filters';
import { dWhiteMotorsRed } from '../constants/colors';

import testCarData from '../JSON/generatedData.json';
import { AutoRecord } from '../types/core';

const testData = testCarData as Array<AutoRecord>;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  padding: 1rem;
`;

const Body = styled.div<{ shouldTransition: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
`;

const InventoryBody = styled.div<{ shouldTransition: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const paginationSx = {
  '.MuiPaginationItem-ellipsis': {
    color: 'black',
    backgroundColor: 'white',
    border: `2px solid ${dWhiteMotorsRed}`,
  },
};

const paginationItemSx = {
  color: 'black',
  backgroundColor: 'white',
  border: `2px solid ${dWhiteMotorsRed}`,

  '&.Mui-selected': {
    color: 'white',
    backgroundColor: dWhiteMotorsRed,
  },

  '&:hover': {
    backgroundColor: dWhiteMotorsRed,
    color: 'white',
  },
};

interface InventoryPageProps {}

export const InventoryPage: FC<InventoryPageProps> = () => {
  const navigate = useNavigate();
  const { ref } = useResizeDetector();
  const [inventory, setInventory] = useState<Array<AutoRecord>>(testData);
  const vehicleFilterState = useSelector(filterStateSelector);
  const [pageIndex, setPageIndex] = useState(0);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);

  const filterInventory = useCallback(() => {
    let filteredInventory = testData;
    if (vehicleFilterState.priceLow) {
      filteredInventory = filteredInventory.filter(
        (car) => parseInt(car.price) >= vehicleFilterState.priceLow
      );
    }

    if (vehicleFilterState.priceHigh) {
      filteredInventory = filteredInventory.filter(
        (car) => parseInt(car.price) <= vehicleFilterState.priceHigh!
      );
    }

    if (vehicleFilterState.vehicleType.length > 0) {
      filteredInventory = filteredInventory.filter((car) =>
        vehicleFilterState.vehicleType.includes(car.vehicleType)
      );
    }

    if (vehicleFilterState.vehicleOptions.length > 0) {
      filteredInventory = filteredInventory.filter((car) => {
        const carExteriorAccessories = car.exteriorAccessories || [];
        const carInteriorAccessories = car.interiorAccessories || [];
        const carSafetyFeatures = car.safetyFeatures || [];
        const carMechanicalFeatures = car.mechanicalFeatures || [];
        const carTechFeatures = car.techFeatures || [];
        const carOtherFeatures = car.otherFeatures || [];
        const carPackages = car.packages || [];

        const combinedOptions = [
          ...carExteriorAccessories,
          ...carInteriorAccessories,
          ...carSafetyFeatures,
          ...carMechanicalFeatures,
          ...carTechFeatures,
          ...carOtherFeatures,
          ...carPackages,
        ];

        return vehicleFilterState.vehicleOptions.filter((option) => {
          if (combinedOptions.includes(option)) {
            return true;
          }
          return false;
        });
      });
    }

    return filteredInventory;
  }, [vehicleFilterState]);

  useEffect(() => {
    const filteredData = filterInventory();
    setInventory(filteredData);
  }, [filterInventory, vehicleFilterState]);

  const inventoryChunks = _.chunk(inventory, 12);
  const NUM_PAGES = inventoryChunks.length;

  const checkDisabledPagination = (pageIndex: number) => {
    if (pageIndex === 0) {
      setPrevDisabled(true);
    } else {
      setPrevDisabled(false);
    }

    if (pageIndex + 1 === NUM_PAGES) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  };

  const handlePaginationClick = (
    type: PaginationItemProps['type'],
    nextPage: number
  ) => {
    if (type === 'page') {
      setPageIndex(nextPage - 1);
      checkDisabledPagination(nextPage - 1);
    }

    if (type === 'next') {
      setPageIndex(pageIndex + 1);
      checkDisabledPagination(pageIndex + 1);
    }

    if (type === 'previous') {
      setPageIndex(pageIndex - 1);
      checkDisabledPagination(pageIndex - 1);
    }
  };

  return (
    <Container ref={ref}>
      <H1>Inventory</H1>
      <Body shouldTransition={false}>
        <InventoryFilterMenu />
        <InventoryBody shouldTransition={false}>
          {inventoryChunks[pageIndex].map((car) => (
            <InventoryCard key={car.id} data={car} />
          ))}
        </InventoryBody>
        <Pagination
          count={NUM_PAGES}
          boundaryCount={10}
          sx={paginationSx}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              shape="rounded"
              variant="outlined"
              disabled={
                (item.type === 'previous' && prevDisabled) ||
                (item.type === 'next' && nextDisabled)
              }
              sx={paginationItemSx}
              selected={item.page === pageIndex + 1}
              onClick={() => handlePaginationClick(item.type, item.page || 0)}
            />
          )}
        />
      </Body>
    </Container>
  );
};
