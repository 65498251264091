import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div``;

interface AdminStatsPageProps {}

export const AdminStatsPage: FC<AdminStatsPageProps> = () => {
  return <Container>AdminStatsPage</Container>;
};
