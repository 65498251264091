import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { DWhiteRedButton } from '../atoms/Button';

import { AutoRecord } from '../../types/core';
import { summonFlashMessage } from '../../helpers/flashMessage';
import { dWhiteRedFadedBoxShadow } from '../../constants/colors';
import fallbackImage from '../../images/No_Car_Images_DWhiteMotors.jpg';

interface InventoryCardProps {
  data: AutoRecord;
}

const cardActionsSx = {
  justifyContent: 'space-between',
  alignItems: 'center',
};

const StyledDWhiteRedButton = styled(DWhiteRedButton)`
  font-size: 16px;
  margin: 0;
`;

const cardSx = {
  maxWidth: 345,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  margin: '10px',
  cursor: 'pointer',

  transition: 'all 0.25s ease-in-out',

  '&:hover': {
    transform: 'scale(1.04, 1.02)',
    boxShadow: `10px 5px 50px ${dWhiteRedFadedBoxShadow}`,
  },
};

export const InventoryCard: FC<InventoryCardProps> = ({ data }) => {
  const navigate = useNavigate();
  const title = `${data.year} ${data.make} ${data.model}`;
  const [isFavorite, setIsFavorite] = useState(false);

  const handleFavoriteClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    summonFlashMessage('Added to Favorites', 'info');
    setIsFavorite(!isFavorite);
  };

  const handleLearnMoreClick = () => navigate(`/inventory/${data.id}`);

  const renderImage =
    data.images && data.images.length ? data.images[0] : fallbackImage;

  return (
    <Card sx={cardSx} onClick={handleLearnMoreClick}>
      <CardMedia
        component="img"
        sx={{ height: 140, objectFit: 'contain', padding: '10px 5px 5px 5px' }}
        src={renderImage}
        image={renderImage}
        title={title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {data.descriptionShort}
        </Typography>
      </CardContent>
      <CardActions sx={cardActionsSx}>
        <IconButton
          aria-label="add to favorites"
          onClick={(e) => handleFavoriteClick(e)}
        >
          {isFavorite ? (
            <FavoriteIcon htmlColor="red" />
          ) : (
            <FavoriteBorderIcon htmlColor="red" />
          )}
        </IconButton>
        <StyledDWhiteRedButton onClick={handleLearnMoreClick}>
          Learn More
        </StyledDWhiteRedButton>
      </CardActions>
    </Card>
  );
};
