import React, { FC } from 'react';
import styled from 'styled-components';
import { dWhiteMotorsDarkerRed, softWhite } from '../../constants/colors';
import { H2, H3 } from '../atoms/Headers';
import { DWhiteRedButton } from '../atoms/Button';

const Wrapper = styled.div`
  margin-top: 1rem;
`;

const Container = styled.div`
  border: 2px solid ${dWhiteMotorsDarkerRed};
  border-radius: 10px;
  background-color: ${softWhite};
  padding: 10px;
`;

const FinancingSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const VehicleSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const Title = styled(H2)`
  margin: 0 0 5px 0;
  font-size: 2rem;
`;

const SubTitle = styled(H3)`
  margin: 0 0 5px 0;
  font-size: 14px;
  color: black;
`;

const StyledDWhiteRedButton = styled(DWhiteRedButton)`
  width: 95%;
  border-radius: 5px;
  align-self: center;
`;

interface UserPreferencesSectionProps {}

export const UserPreferencesSection: FC<UserPreferencesSectionProps> = () => {
  return (
    <Wrapper>
      <Title>Preferences</Title>
      <Container>
        <FinancingSection>
          <Title style={{ color: 'black', fontSize: '20px' }}>Financing</Title>
          <SubTitle style={{ margin: 0 }}>Credit Score</SubTitle>
          <SubTitle style={{ margin: 0 }}>Price Range</SubTitle>
        </FinancingSection>
        <VehicleSection>
          <Title style={{ color: 'black', fontSize: '20px' }}>Vehicle</Title>
          <SubTitle>Interior Accessories</SubTitle>
          <SubTitle>Exterior Accessories</SubTitle>
          <SubTitle>Vehicle Type/Styles</SubTitle>
          <SubTitle>Vehicle Packages</SubTitle>
          <SubTitle>Tech Features</SubTitle>
          <SubTitle>Mechanical Features</SubTitle>
          <SubTitle>Safety Features</SubTitle>
          <SubTitle>Other Features</SubTitle>
          <StyledDWhiteRedButton>Edit</StyledDWhiteRedButton>
        </VehicleSection>
      </Container>
    </Wrapper>
  );
};
