import React, { FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

import { dWhiteMotorsRed } from '../../constants/colors';
import {
  calculateFinanceRate,
  getTierLabel,
} from '../../helpers/financeCalculator';
import { CreditScoreButtonsWithRates } from './CreditScoreBoxes';

const marks = [
  {
    value: 400,
    label: '400',
  },
  {
    value: 500,
    label: '500',
  },
  {
    value: 600,
    label: '600',
  },
  {
    value: 700,
    label: '700',
  },
  {
    value: 800,
    label: '800',
  },
  {
    value: 850,
    label: '850',
  },
];

const DWhiteSlider = styled(Slider)({
  color: dWhiteMotorsRed,
  height: 10,

  '& .MuiSlider-thumb': {
    height: 25,
    width: 25,
    backgroundColor: dWhiteMotorsRed,
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-markLabel': {
    color: dWhiteMotorsRed,
    fontSize: 14,
    fontWeight: 700,
  },
});

const typographySx = {
  marginBottom: '2.5rem',
};

const valuetext = (value: number) => {
  const output = `${value} - ${getTierLabel(value)}`;
  return output;
};

interface CreditScoreSliderProps {
  onSliderChange: (value: number) => void;
}

export const CreditScoreSlider: FC<CreditScoreSliderProps> = ({
  onSliderChange,
}) => {
  const [value, setValue] = useState<number>(600);
  return (
    <Box sx={{ width: '90%' }}>
      <Typography sx={typographySx} id="credit-score-slider" gutterBottom>
        Estimated Credit Score
      </Typography>
      <DWhiteSlider
        track={false}
        max={850}
        valueLabelDisplay="auto"
        min={400}
        value={value}
        onChange={(_, newValue) => {
          setValue(newValue as number);
          onSliderChange(calculateFinanceRate(newValue as number));
        }}
        step={1}
        aria-labelledby="credit-score-slider"
        getAriaValueText={valuetext}
        valueLabelFormat={valuetext}
        marks={marks}
      />
      <CreditScoreButtonsWithRates rate={value} />
    </Box>
  );
};
