export type tierType = {
  label: string;
  min: number;
  max: number;
  rate: number;
};

export const financeTiers = {
  t0: {
    label: 'Deep Subprime',
    min: 0,
    max: 500,
    rate: 21.55,
  },
  t1: {
    label: 'Subprime',
    min: 501,
    max: 600,
    rate: 18.89,
  },
  t2: {
    label: 'Nonprime',
    min: 601,
    max: 660,
    rate: 14.12,
  },
  t3: {
    label: 'Prime',
    min: 661,
    max: 780,
    rate: 9.73,
  },
  t4: {
    label: 'Superprime',
    min: 781,
    max: 850,
    rate: 7.66,
  },
};

export const calculateMonthlyPayment = (
  loanAmount: number,
  term: number,
  rate: number
) => {
  const monthlyRate = rate / 100 / 12;
  const monthlyPayment =
    (loanAmount * monthlyRate) / (1 - Math.pow(1 + monthlyRate, -term));
  return monthlyPayment;
};

export type calcRatesForVaryingMonthsOutput = {
  [x: number]: number;
}[];

export const calculatedMonths = [12, 24, 36, 48];

export const calcRatesForVaryingMonths = (
  remainingBalance: number,
  interestRate: number
): calcRatesForVaryingMonthsOutput => {
  const rates = calculatedMonths.map((month) => ({
    [month]: Number(
      calculateMonthlyPayment(remainingBalance, month, interestRate).toFixed(2)
    ),
  }));
  return rates;
};

export const calculateFinanceTier = (creditScore: number) => {
  if (creditScore <= 500) {
    return financeTiers.t0;
  } else if (creditScore <= 600) {
    return financeTiers.t1;
  } else if (creditScore <= 660) {
    return financeTiers.t2;
  } else if (creditScore <= 780) {
    return financeTiers.t3;
  } else {
    return financeTiers.t4;
  }
};

export const calculateFinanceRate = (creditScore: number) => {
  const tier = calculateFinanceTier(creditScore);
  return tier.rate;
};

export const getTierLabel = (creditScore: number) => {
  const tier = calculateFinanceTier(creditScore);
  return tier.label;
};
