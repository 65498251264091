import React from 'react';
import { useNavigate } from 'react-router';
import { useResizeDetector } from 'react-resize-detector';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { dWhiteMotorsRed } from '../../constants/colors';

import leads from '../../JSON/generatedLeads.json';

const rows = [
  {
    id: 'w18xyldz0xhh',
    firstName: 'Kailee',
    lastName: 'Balistreri',
    status: 'New',
    lastActivity: 'Yesterday',
  },
  {
    id: 'df9dau3gpuou',
    firstName: 'Chase',
    lastName: 'Armstrong',
    status: 'New',
    lastActivity: '7 Days Ago',
  },
  {
    id: 'qqgtslfavj8w',
    firstName: 'Luther',
    lastName: 'Krajcik',
    status: 'New',
    lastActivity: '3 Days Ago',
  },
  {
    id: '050qkuk8ar4t',
    firstName: 'Jaden',
    lastName: 'Turner',
    status: 'Active',
    lastActivity: 'Yesterday',
  },
  {
    id: '6jgs04b6gtne',
    firstName: 'Jarod',
    lastName: 'Cremin',
    status: 'Active',
    lastActivity: '4 Days Ago',
  },
  {
    id: '6ky5u9mkpwzy',
    firstName: 'Flavio',
    lastName: 'Skiles',
    status: 'Contacted',
    lastActivity: 'Yesterday',
  },
  {
    id: 'kihqsu3j1u0b',
    firstName: 'Hayden',
    lastName: 'Armstrong',
    status: 'Pending',
    lastActivity: 'Yesterday',
  },
  {
    id: 'qxn3gr5lrn0h',
    firstName: 'Rosanna',
    lastName: 'Terry',
    status: 'Pending',
    lastActivity: 'Yesterday',
  },
  {
    id: 'lvsy9s7apnlz',
    firstName: 'Eve',
    lastName: 'Upton',
    status: 'Closed',
    lastActivity: '2 Weeks Ago',
  },
];

const tableSx = {
  fontSize: '1.2rem',
  color: 'white',

  '& .MuiDataGrid-row': {
    padding: '0 10px',
    color: 'white',
  },
  '& .MuiDataGrid-columnHeaders': {
    padding: '0 10px',
    backgroundColor: 'white',
    color: 'black',
  },
  '& .MuiTablePagination-selectLabel': {
    color: 'white',
  },
  '.MuiCheckbox-root': {
    color: 'white',

    '&.Mui-checked': {
      color: dWhiteMotorsRed,
    },
  },
  '& .MuiTablePagination-select': {
    color: 'white',
  },
  '& .MuiTablePagination-displayedRows': {
    color: 'white',
  },
  '& .MuiTablePagination-actions': {
    color: 'white',

    '.MuiButtonBase-root': {
      '.Mui-disabled': {
        color: 'transparent',
      },
    },
  },
};

export const LeadsTable = () => {
  const { ref, width } = useResizeDetector();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: width ? width * 0.15 : 100 },
    {
      field: 'firstName',
      headerName: 'First name',
      width: width ? width * 0.2 : 130,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: width ? width * 0.2 : 130,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: width ? width * 0.2 : 130,
    },
    {
      field: 'lastActivity',
      headerName: 'Last Activity',
      description: 'This column has a value getter and is not sortable.',
      width: width ? width * 0.3 : 200,
    },
  ];

  return (
    <div style={{ height: '100%', width: '100%' }} ref={ref}>
      <DataGrid
        sx={tableSx}
        rows={rows}
        onCellDoubleClick={(cell) => navigate(`/profile/${cell.row.id}`)}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 25, 50]}
      />
    </div>
  );
};
