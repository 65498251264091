import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { useAppDispatch } from '../../store';
import { isAdminSelector, setSideBar } from '../../store/site';

import { Button } from '../atoms/Button';

import { dWhiteMotorsRed, softWhite } from '../../constants/colors';
import { PATHS } from '../../constants/paths';
import { propelAuthUserInfoSelector } from '../../store/account';
import { summonFlashMessage } from '../../helpers/flashMessage';

const Container = styled.div`
  display: flex;
  height: 100%;
  padding: 1rem 1rem 0 1rem;
  flex-direction: column;
  background-color: ${softWhite};
  justify-content: space-between;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & > *:last-child {
    margin-top: auto;
    padding-bottom: 3rem;
  }
`;

const NavigationItem = styled(Button)<{
  isCurrent: boolean;
  disabled?: boolean;
}>`
  color: ${({ disabled }) => (disabled ? 'gray' : 'black')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'cursor')};
  margin: 12px 12px 0 0px;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 0;
  text-align: left;
  ${({ isCurrent }) =>
    isCurrent &&
    `
      border-bottom: 5px solid ${dWhiteMotorsRed};
      padding-bottom: 10px;
    `}

  &:hover {
    color: ${({ disabled }) => (disabled ? 'gray' : dWhiteMotorsRed)};
  }
`;

export const LeftDrawerMenu: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAdmin = useSelector(isAdminSelector);
  const userData = useSelector(propelAuthUserInfoSelector);

  const handleNavClick = (path: string, disabled: boolean) => {
    if (disabled) {
      summonFlashMessage('This feature is not available yet', 'info');
      return;
    }

    dispatch(setSideBar(false));
    navigate(path);
  };

  type navListType = {
    name: string;
    path: string;
    disabled?: boolean;
  };

  const customerNavList: Array<navListType> = [
    { name: 'Home', path: PATHS.landing.path },
    { name: 'Inventory', path: PATHS.inventory.path },
    { name: 'Contact D!', path: PATHS.contact.path },
    // { name: 'Reviews', path: PATHS.reviews.path },
  ];

  if (!!userData) {
    customerNavList.push({ name: 'My Profile', path: PATHS.profile.path });
  }

  const adminNavList: Array<navListType> = [
    { name: 'Home', path: PATHS.landing.path },
    { name: 'Inventory', path: PATHS.inventory.path },
    { name: 'Leads', path: PATHS.leads.path },
    { name: 'Financing Calculator', path: PATHS.financing.path },
    { name: 'Stats', path: PATHS.stats.path, disabled: true },
    { name: 'Reviews', path: PATHS.reviews.path, disabled: true },
    { name: 'Users', path: PATHS.users.path, disabled: true },
  ];

  const navList = isAdmin ? adminNavList : customerNavList;
  const currentPath = window.location.pathname;

  return (
    <Container>
      <Section>
        {navList.map((navItem) => (
          <NavigationItem
            disabled={navItem.disabled}
            key={navItem.name}
            isCurrent={navItem.path === currentPath}
            onClick={() => {
              if (navItem.path === currentPath) return;
              handleNavClick(navItem.path, !!navItem.disabled);
            }}
          >
            {navItem.name}
          </NavigationItem>
        ))}
      </Section>
    </Container>
  );
};
