import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { RootState } from './index';

import { AutoRecord } from '../types/core';
import {
  vehicleExteriorAccessories,
  interiorVehicleAccessories,
  vehicleSafetyFeatures,
  vehicleMechanicalFeatures,
  vehicleTechFeatures,
  additionalVehicleFeatures,
  vehiclePackages,
} from '../constants/vehicleInfo';

export type VehicleOptionsType =
  | (typeof vehicleExteriorAccessories)[number]
  | (typeof interiorVehicleAccessories)[number]
  | (typeof vehicleSafetyFeatures)[number]
  | (typeof vehicleMechanicalFeatures)[number]
  | (typeof vehicleTechFeatures)[number]
  | (typeof additionalVehicleFeatures)[number]
  | (typeof vehiclePackages)[number];

export interface FilterState {
  priceLow: number;
  priceHigh: number | undefined;
  vehicleType: Array<AutoRecord['vehicleType']>;
  vehicleOptions: Array<VehicleOptionsType>;
}

const generateInitialState = (): FilterState => ({
  priceLow: 0,
  priceHigh: undefined,
  vehicleType: [],
  vehicleOptions: [],
});

// ASYNC THUNKS

// REDUCERS
const filterSlice: Slice = createSlice({
  name: 'filter',
  initialState: generateInitialState(),
  reducers: {
    updateFilters: (
      state: FilterState,
      action: PayloadAction<Partial<FilterState>>
    ) => ({
      ...state,
      ...action.payload,
    }),
    resetFilters: () => generateInitialState(),
  },
  extraReducers: (builder) => {},
});

// SELECTORS
export const filterStateSelector = (state: RootState): FilterState =>
  state.filter;

// EXPORTS
export const { updateFilters, resetFilters } = filterSlice.actions;
export const filterReducer = filterSlice.reducer;
