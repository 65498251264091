import React from 'react';
import styled from 'styled-components';
import { dWhiteMotorsRed, softWhite } from '../../constants/colors';

export const Button = styled.button<
  React.ComponentProps<'button'> & React.HTMLAttributes<HTMLButtonElement>
>`
  transition: all 0.25s ease-in-out;
  padding: 8px 35px;
  border-radius: 25px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  border: none;
  background-color: transparent;

  :hover {
    ${({ disabled }) =>
      !disabled &&
      `
      transform: scale(1.04, 1.02);
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
      `}
  }
`;

export const DWhiteRedButton = styled(Button)`
  color: black;
  background-color: ${dWhiteMotorsRed};
  width: 70%;
  margin-top: 1rem;
  font-size: 1.2rem;

  &:hover {
    transform: scale(1.04, 1.02);
    box-shadow: 2px 2px 5px ${softWhite};
    color: ${softWhite};
  }
`;
