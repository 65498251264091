import React, { FC } from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

import { dWhiteMotorsDarkerRed } from '../../constants/colors';
import { H1 } from '../atoms/Headers';
import { LeadRecord } from '../../types/core';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  background-color: ${dWhiteMotorsDarkerRed};
  border-radius: 10px;
  padding: 15px;
  align-self: center;
  margin: 1rem 0;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled(H1)`
  margin: 0 0 2rem 0;
`;

const ContactSpan = styled.span``;

interface ProfileBannerProps {
  user: LeadRecord;
  isAdmin: boolean;
}

export const ProfileBanner: FC<ProfileBannerProps> = ({ user, isAdmin }) => {
  const fullName = user.firstName + ' ' + user.lastName;
  const phoneNumber = user.phone;
  const email = user.email;
  const id = user.id;

  const handleEditProfileClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    console.log('Favorite clicked');
  };

  return (
    <Container>
      <Section style={{ alignItems: 'flex-start' }}>
        <Name>
          {isAdmin ? 'Viewing' : 'Hello'} {fullName}
        </Name>
        <ContactSpan>Phone: {phoneNumber}</ContactSpan>
        <ContactSpan>Email: {email}</ContactSpan>
      </Section>
      <Section
        style={{ alignItems: 'flex-end', justifyContent: 'space-between' }}
      >
        <IconButton
          aria-label="Edit Profile"
          sx={{ margin: '-10px 0 0 0' }}
          onClick={(e) => handleEditProfileClick(e)}
        >
          <EditNoteOutlinedIcon sx={{ color: 'white' }} />
        </IconButton>

        <ContactSpan>ID: {id}</ContactSpan>
      </Section>
    </Container>
  );
};
