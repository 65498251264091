import React, { FC } from 'react';
import styled from 'styled-components';
import { dWhiteMotorsDarkerRed, softWhite } from '../../constants/colors';
import { AutoRecord } from '../../types/core';
import { Button } from '../atoms/Button';
import { H3 } from '../atoms/Headers';
import { IDSpan } from '../atoms/Id';
import { Tag } from '../atoms/Tag';
import { useNavigate } from 'react-router';

const Container = styled(Button)`
  width: 100%;
  display: flex;
  border: 2px solid ${dWhiteMotorsDarkerRed};
  border-radius: 10px;
  background-color: ${softWhite};
  padding: 10px;
  margin: 12px 0;

  :hover {
    transform: none;
    box-shadow: none;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const YearSpan = styled.span``;

const MakeModelSpan = styled(H3)`
  margin: 0;
`;

const IdSpan = styled(IDSpan)``;

const StatusTag = styled(Tag)`
  .MuiChip-label {
    color: black;
  }
`;

interface ProfileFavoriteCardProps {
  item: AutoRecord;
}

export const ProfileFavoriteCard: FC<ProfileFavoriteCardProps> = ({ item }) => {
  const navigate = useNavigate();
  const { make, model, year, id, salesStatus } = item;

  const handleClick = () => navigate(`/inventory/${id}`);

  return (
    <Container onClick={handleClick}>
      <Section style={{ alignItems: 'flex-start' }}>
        <MakeModelSpan>
          {make} {model}
        </MakeModelSpan>
        <YearSpan>{year}</YearSpan>
        <StatusTag label={salesStatus || 'Unknown'} />
      </Section>
      <Section style={{ alignItems: 'flex-end' }}>
        <IdSpan>{id}</IdSpan>
      </Section>
    </Container>
  );
};
