import { User } from '@propelauth/react';
import { RootState } from './index';
import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';

export interface AccountState {
  profileId: string;
  propelAuthId: string;
  name: string;
  email: string;
  propelAuthUserInfo: User | null;
}

const generateInitialState = (): AccountState => ({
  profileId: '',
  propelAuthId: '',
  name: '',
  email: '',
  propelAuthUserInfo: null,
});

// ASYNC THUNKS

// REDUCERS
const accountSlice: Slice = createSlice({
  name: 'account',
  initialState: generateInitialState(),
  reducers: {
    setEmail: (state: AccountState, action: PayloadAction<string>) => ({
      ...state,
      email: action.payload,
    }),
    setName: (state: AccountState, action: PayloadAction<string>) => ({
      ...state,
      name: action.payload,
    }),
    setProfileId: (state: AccountState, action: PayloadAction<string>) => ({
      ...state,
      profileId: action.payload,
    }),
    setPropelAutoUserInfo: (
      state: AccountState,
      action: PayloadAction<User>
    ) => ({
      ...state,
      propelAuthUserInfo: action.payload,
    }),
  },
  extraReducers: (builder) => {},
});

// SELECTORS
export const profileIdSelector = (state: RootState): string =>
  state.account.profileId;
export const emailSelector = (state: RootState) => state.account.email;
export const usernameSelector = (state: RootState) => state.account.name;
export const propelAuthIdSelector = (state: RootState) =>
  state.account.propelAuthId;
export const propelAuthUserInfoSelector = (state: RootState): User | null =>
  state.account.propelAuthUserInfo;

// EXPORTS
export const { setEmail, setName, setProfileId, setPropelAutoUserInfo } =
  accountSlice.actions;
export const accountReducer = accountSlice.reducer;
