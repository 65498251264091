import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useResizeDetector } from 'react-resize-detector';
import { useAuthInfo } from '@propelauth/react';

import { ProfileBanner } from '../components/organisms/ProfileBanner';
import { FavoritesSection } from '../components/organisms/FavoritesSection';
import { DocumentsSection } from '../components/organisms/DocumentsSection';
import { UserPreferencesSection } from '../components/organisms/UserPreferencesSection';

import userData from '../JSON/generatedLeads.json';
import { summonFlashMessage } from '../helpers/flashMessage';
import { isAdminSelector } from '../store/site';

const Container = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
`;

const BodyContent = styled.div<{ shouldTransition: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 98%;
  align-self: center;

  ${({ shouldTransition }) =>
    shouldTransition &&
    `
    flex-direction: column;
    justify-content: center;
  `}
`;

const Section = styled.div<{ width: number }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width}%;
`;

interface ProfilePageProps {}

export const ProfilePage: FC<ProfilePageProps> = () => {
  const { userId } = useParams<{ userId: string }>();
  const { width, ref } = useResizeDetector();
  const navigate = useNavigate();
  const isAdmin = useSelector(isAdminSelector);
  const propelAuthId = useAuthInfo()?.user?.userId;

  const hasValidPermissions = isAdmin || userId === propelAuthId;

  useEffect(() => {
    if (!hasValidPermissions) {
      navigate(-1);
      summonFlashMessage(
        'You do not have permissions to view this content',
        'warning'
      );
    }
  }, [hasValidPermissions, navigate]);

  const user = userData.find((user) => user.id === userId);

  if (!user) {
    navigate(-1);
    summonFlashMessage('User not found', 'warning');
    return;
  }

  const shouldTransition = width && width < 601;

  return (
    <Container ref={ref}>
      <ProfileBanner user={user} isAdmin={isAdmin} />
      <BodyContent shouldTransition={!!shouldTransition}>
        <Section width={!!shouldTransition ? 100 : 55}>
          <FavoritesSection />
        </Section>
        <Section width={!!shouldTransition ? 100 : 40}>
          <DocumentsSection />
          <UserPreferencesSection />
        </Section>
      </BodyContent>
    </Container>
  );
};
