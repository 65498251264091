import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div``;

interface PlaygroundPageProps {}

export const PlaygroundPage: FC<PlaygroundPageProps> = () => {
  return <Container>PlaygroundPage</Container>;
};
