import React, { FC, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { useTheme } from '@mui/material/styles';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import fallbackImage from '../../images/No_Car_Images_DWhiteMotors.jpg';
import { dWhiteMotorsRed } from '../../constants/colors';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const mobileStepperSx = {
  '& .MuiMobileStepper-dot': {},

  '& .MuiMobileStepper-dotActive': {
    backgroundColor: dWhiteMotorsRed,
  },
};

interface ImageCarouselProps {
  invName: string;
  images?: Array<string>;
}

export const ImageCarousel: FC<ImageCarouselProps> = ({ invName, images }) => {
  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(0);

  const renderImages = images?.length
    ? images?.map((image, index) => {
        return {
          label: `${invName}-image-${index}`,
          imgPath: image,
        };
      })
    : [
        {
          label: `No Images Available for ${invName}`,
          imgPath: fallbackImage,
        },
      ];

  const maxSteps = renderImages.length;

  const handleNext = () =>
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleStepChange = (step: number) => setActiveStep(step);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
        <Typography sx={{ textAlign: 'center' }}>
          {renderImages[activeStep].label}
        </Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {renderImages.map((step, index) => (
          <div
            key={step.label}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: 255,
                  display: 'block',
                  maxWidth: 400,
                  overflow: 'hidden',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        sx={mobileStepperSx}
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            style={{
              color:
                activeStep === maxSteps - 1
                  ? 'rgba(0, 0, 0, 0.3)'
                  : dWhiteMotorsRed,
            }}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
            style={{
              color: activeStep === 0 ? 'rgba(0, 0, 0, 0.3)' : dWhiteMotorsRed,
            }}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
};
