import React, { FC, ReactNode, SyntheticEvent } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import MUITabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { dWhiteMotorsRed, softWhite } from '../../constants/colors';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

export type TabsProps = {
  name: string;
  data: Array<{
    label: string;
    content: ReactNode;
  }>;
  onChange: (newValue: number) => void;
  value: number;
};

const tabMenuSx = {
  '&.MuiTabs-root': {
    width: '100%',
    alignItems: 'center',
  },

  '.MuiTabs-indicator': {
    backgroundColor: dWhiteMotorsRed,
  },

  '.MuiTabs-scroller': {
    // display: 'contents',
  },
};

const tabSx = {
  '&.MuiTab-root': {
    color: softWhite,
    fontWeight: 700,
    justifyContent: 'center',

    '&.Mui-selected': {
      color: dWhiteMotorsRed,
    },
  },
};

export const Tabs: FC<TabsProps> = ({ name, data, onChange, value }) => {
  const { width, ref } = useResizeDetector();

  const shouldMaxWidthTabPanel = width && width <= 675;

  const handleChange = (_: SyntheticEvent, newValue: number) =>
    onChange(newValue);

  const a11yProps = (index: number) => {
    return {
      id: `${name}-tab-${index}`,
      'aria-controls': `${name}-tabpanel-${index}`,
    };
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    const tabPanelSx = {
      width: shouldMaxWidthTabPanel ? '100%' : '80%',
      marginTop: '1rem',
      alignItems: 'center',
      border: `2px solid ${dWhiteMotorsRed}`,
      borderRadius: '15px',
      backgroundColor: softWhite,
    };

    return (
      <div
        role="tabpanel"
        style={tabPanelSx}
        hidden={value !== index}
        id={`${name}-tabpanel-${index}`}
        aria-labelledby={`${name}-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3, alignItems: 'center' }}>{children}</Box>
        )}
      </div>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      ref={ref}
    >
      <MUITabs
        centered
        sx={tabMenuSx}
        value={value}
        onChange={handleChange}
        aria-label={`${name}-tabs`}
      >
        {data.map((item, index) => (
          <Tab
            sx={tabSx}
            label={item.label}
            key={`${index}-tab`}
            {...a11yProps(index)}
          />
        ))}
      </MUITabs>
      {data.map((item, index) => (
        <TabPanel value={value} key={`${index}-tabPanel`} index={index}>
          {item.content}
        </TabPanel>
      ))}
    </Box>
  );
};
