export const PATHS = {
  landing: { path: '/', isAdmin: false },
  inventory: { path: '/inventory', isAdmin: false },
  favorites: { path: '/favorites', isAdmin: false },
  inventoryItem: { path: '/inventory/:id', isAdmin: false },
  reviews: { path: '/reviews', isAdmin: false },
  contact: { path: '/contact', isAdmin: false },
  profile: { path: '/profile/:userId', isAdmin: false },
  // admin: { path: '/admin', isAdmin: true },
  // inventoryManagement: { path: '/manage-inventory', isAdmin: true },
  playground: { path: '/playground', isAdmin: true },
  leads: { path: '/leads', isAdmin: true },
  lead: { path: '/lead/:id', isAdmin: true },
  financing: { path: '/financing', isAdmin: true },
  stats: { path: '/stats', isAdmin: true },
  users: { path: '/users', isAdmin: true },
  user: { path: '/user/:id', isAdmin: true },
};
