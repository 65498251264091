import React, { FC } from 'react';
import styled from 'styled-components';
import { H1 } from '../components/atoms/Headers';
import { LeadsTable } from '../components/molecules/LeadsTable';

const Container = styled.div`
  width: 95%;
`;

interface LeadsPageProps {}

export const LeadsPage: FC<LeadsPageProps> = () => {
  return (
    <Container>
      <H1>Leads</H1>
      <LeadsTable />
    </Container>
  );
};
