import React, { FC } from 'react';
import styled from 'styled-components';
import { CardMedia, Chip, SxProps, Theme, Typography } from '@mui/material';
import { useResizeDetector } from 'react-resize-detector';

import { H1 } from '../components/atoms/Headers';
import { DWhiteRedButton } from '../components/atoms/Button';

import { dWhiteMotorsRed, softWhite } from '../constants/colors';
import { useNavigate } from 'react-router';
import { PATHS } from '../constants/paths';

const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
`;

const Body = styled.div<{ shouldTransition: boolean }>`
  display: flex;
  flex-direction: ${({ shouldTransition }) =>
    shouldTransition ? 'column' : 'row'};
  align-items: center;
  gap: 20px;
`;

const chipSx: SxProps<Theme> = {
  alignSelf: 'flex-start',

  '&.MuiChip-root': {
    marginTop: '10px',
    backgroundColor: 'white',
    border: `2px solid ${dWhiteMotorsRed}`,
    color: 'black',
    height: '30px',
    fontSize: '14px',
  },
};

const openingText = `
Hey there, I'm D White, owner of D White Motors. At our dealership, I'm all about honesty, openness, and lending a hand to help you discover your perfect ride. I personally select each vehicle in our inventory, focusing on quality and reliability. \n

My goal is to make your car-buying experience seamless and enjoyable. From browsing our curated selection to driving off in your new ride, I'm here to guide you with trust and personalized service. \n

At D White Motors, it's more than a business – it's a commitment to connecting you with the right vehicle for your lifestyle. Let's embark on this journey together, where your next ride isn't just a purchase; it's a decision made with confidence and backed by excellence. Join me at D White Motors, and let's find the keys to your driving dreams.
`;

const LandingPage: FC = () => {
  const { width, ref } = useResizeDetector();
  const navigate = useNavigate();

  const shouldTransition = width && width < 930;

  const handleClick = () => navigate(PATHS.inventory.path);

  return (
    <Container ref={ref}>
      <H1>Welcome to DWhite Motors</H1>
      <Body shouldTransition={!!shouldTransition}>
        <CardMedia
          component={'img'}
          sx={{ maxHeight: 500, maxWidth: 500 }}
          image={require('../images/DWhiteLandingPhoto.png')}
          title={'DWhite Motors'}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: softWhite,
              whiteSpace: 'pre-wrap',
              fontSize: '16px',
              fontFamily: 'Montserrat',
            }}
          >
            {openingText}
          </Typography>
          <Chip sx={chipSx} label="#GetRightWithDWhite" />
          {!shouldTransition && (
            <DWhiteRedButton onClick={handleClick}>
              See Inventory
            </DWhiteRedButton>
          )}
        </div>
      </Body>
      {shouldTransition && (
        <DWhiteRedButton onClick={handleClick}>See Inventory</DWhiteRedButton>
      )}
    </Container>
  );
};

export default LandingPage;
