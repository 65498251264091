export const vehicleExteriorAccessories = [
  'Rubber mud flaps',
  'Molded splash guards',
  'Roof-mounted bike racks',
  'Front-end grille protectors',
  'Nerf bars',
  'Retractable steps',
  'Electric running boards',
  'Hitch covers',
  'Hitch step bars',
  'Spare tire covers',
  'Bed liners',
  'Tonneau covers',
  'Wheel locks',
  'Decorative lug nuts',
] as const;

export const interiorVehicleAccessories = [
  'Leather seat covers',
  'Neoprene seat covers',
  'Custom-fit seat covers',
  'All-weather floor mats',
  'Carpet floor mats',
  'Rubber floor mats',
  'Woodgrain covers',
  'LED interior lights',
  'Underglow lighting',
  'Ambient lighting kits',
  'Universal cup holders',
  'Console cup holders',
  'Memory foam cushions',
  'Gel seat cushions',
  'Heated seat cushions',
  'Trunk liners',
  'Cargo nets',
  'Car chargers',
  'Bluetooth adapters',
  'Headrest DVD players',
  'Tablet/Laptop mounts',
] as const;

export const vehicleSafetyFeatures = [
  'Airbags',
  'Anti-lock Braking System (ABS)',
  'Electronic Stability Control (ESC)',
  'Traction Control System (TCS)',
  'Adaptive Cruise Control',
  'Blind Spot Monitoring',
  'Lane Departure Warning',
  'Forward Collision Warning',
  'Automatic Emergency Braking (AEB)',
  'Collision Avoidance System',
  'Backup Cameras',
  'Parking Sensors',
  'Tire Pressure Monitoring System (TPMS)',
  'Side-Impact Airbags',
  'Curtain Airbags',
  'Child Safety Locks',
  'Pedestrian Detection System',
  'Rearview Camera',
  'Automatic High Beams',
] as const;

export const vehicleMechanicalFeatures = [
  'Suspension System',
  'Brake System',
  'Exhaust System',
  'Turbocharger',
  'Supercharger',
  'Transmission Modes',
  'Traction Control',
  'Engine Start/Stop System',
  'Cruise Control',
  'Electric Power Steering (EPS)',
  'All-Wheel Drive (AWD)',
  'Four-Wheel Drive (4WD)',
  'Two-Wheel Drive (2WD)',
  'Manual Transmission',
  'Automatic Transmission',
  'Adjustable Suspension Settings',
] as const;

export const vehicleTechFeatures = [
  'Touchscreen Display',
  'Navigation System',
  'Apple CarPlay Compatibility',
  'Android Auto Compatibility',
  'Bluetooth Connectivity',
  'USB Ports',
  'Smartphone Integration',
  'Voice Recognition',
  'Digital Instrument Cluster',
  'HUD (Head-Up Display)',
  'Wi-Fi Hotspot',
  'Keyless Entry',
  'Push-Button Start',
  'Remote Start',
  'Lane Keep Assist',
  '360-Degree Camera System',
  'Automated Parking System',
  'Rain-Sensing Wipers',
  'Surround Sound System',
  'Premium Audio Options',
  'Dual-Zone Climate Control',
  'Heated and Ventilated Seats',
  'Heated Steering Wheel',
  'Auto-Dimming Rearview Mirror',
  'Power Liftgate',
  'Hands-Free Tailgate',
] as const;

export const additionalVehicleFeatures = [
  'Power Adjustable Seats',
  'Memory Settings for Driver',
  'Heated Side Mirrors',
  'Power-Folding Side Mirrors',
  'Privacy Glass',
  'Traffic Jam Assist',
  'Road Sign Recognition',
  'Driver Alertness Monitoring',
  'Massaging Seats',
  'Soft-Close Doors',
  'Anti-Theft System',
] as const;

export const vehiclePackages = [
  'Cold Weather Package',
  'Luxury Package',
  'Sport Package',
  'Off-Road Package',
  'Towing Package',
  'Entertainment Package',
  'Navigation Package',
  'Safety Package',
  'Performance Package',
  'All-Weather Package',
  'Family Entertainment Package',
  'Premium Sound Package',
  'Touring Sport Package',
] as const;

export const truckBedLengths = {
  'Short Bed': '5.5 ft',
  'Standard Bed': '6.5 ft',
  'Long Bed': '8 ft',
};

export const vehicleTypes = [
  'Sedan',
  'SUV',
  'Truck',
  'Hatchback',
  'Convertible',
  'Coupe',
  'Van',
  'Crossover',
  'Minivan',
  'Sports Car',
  'Electric Vehicle (EV)',
  'Hybrid',
  'Off-Road Vehicle',
  'Utility Vehicle',
  'Motorcycle',
] as const;
