import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div``;

interface MyFavoritesPageProps {}

export const MyFavoritesPage: FC<MyFavoritesPageProps> = () => {
  return <Container>MyFavoritesPage</Container>;
};
