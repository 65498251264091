import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div``;

interface UserManagementPageProps {}

export const UserManagementPage: FC<UserManagementPageProps> = () => {
  return <Container>UserManagementPage</Container>;
};
