import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import TextField from '@mui/material/TextField/TextField';
import { useResizeDetector } from 'react-resize-detector';

import { H2, H3, H4 } from '../components/atoms/Headers';
import { DWhiteRedButton } from '../components/atoms/Button';

import { CreditScoreSlider } from '../components/molecules/CreditScoreSlider';

import { dWhiteMotorsRed } from '../constants/colors';
import {
  calcRatesForVaryingMonths,
  calcRatesForVaryingMonthsOutput,
  calculatedMonths,
  financeTiers,
  tierType,
} from '../helpers/financeCalculator';
import { Loader } from '../components/atoms/Loader';
import { financeDisclaimer } from '../constants/disclaimers';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
`;

const Section = styled.form<{ transition: boolean }>`
  display: flex;
  justify-content: space-between;

  ${({ transition }) =>
    transition &&
    `
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`;

const InputSection = styled.div<{ transition: boolean }>`
  display: flex;
  flex-direction: column;
  width: 45%;
  min-width: 300px;

  ${({ transition }) =>
    transition &&
    `
    width: 80%;
  `}
`;

const Title = styled(H2)`
  margin: 1rem 0;
  font-size: 2rem;
`;

const SubTitle = styled(H3)`
  margin: 0 0 5px 0;
  font-size: 1.5rem;
  align-self: flex-start;
`;

const MonthTitle = styled(H4)`
  margin: 0 0 5px 0;
  font-size: 1.5rem;
`;

const MonthValue = styled.span`
  margin: 0 0 5px 0;
  font-size: 2rem;
`;

const CalculateButton = styled(DWhiteRedButton)`
  align-self: center;
`;

const MonthlyCalcsSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-top: 2rem;
`;

const MonthlyPaymentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  border-left: 1px solid gray;
  border-right: 1px solid gray;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
`;

const MonthlyRatesWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  align-items: center;
`;

const DisclaimerText = styled.p<{ smallScreen: boolean }>`
  padding: ${({ smallScreen }) => (smallScreen ? '1rem' : '1rem 5rem')};
`;

const inputSx = {
  margin: '20px 0',

  input: {
    '&::placeholder': {
      color: 'black',
    },
  },

  '& .MuiOutlinedInput-input': {
    padding: '10px',
    backgroundColor: 'white',
    borderRadius: '5px',
    border: `2px solid ${dWhiteMotorsRed}`,
  },

  '& .MuiInputBase-multiline': {
    width: '100%',
    padding: 0,
  },
};

const labelSx = {
  '&.MuiInputLabel-root': {
    lineHeight: 1,
    color: 'gray',
    marginTop: '-10px',
    fontSize: '18px',
  },
};

type FormValues = {
  basePrice: number;
  downPayment?: number;
  tradeInVal?: number;
};

interface FinanceCalcPageProps {}

export const FinanceCalcPage: FC<FinanceCalcPageProps> = () => {
  const { width, ref } = useResizeDetector();
  const [creditScoreRate, setCreditScoreRate] = useState<tierType['rate']>(
    financeTiers.t1.rate
  );
  const [hasCalculated, setHasCalculated] = useState(false);
  const [isLoadingCalculations, setIsLoadingCalculations] = useState(false);
  const [monthlyRates, setMonthlyRates] =
    useState<calcRatesForVaryingMonthsOutput | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const { basePrice, downPayment = 0, tradeInVal = 0 } = data;

    setIsLoadingCalculations(true);

    const safelyConvertToNumber = (val: number): number =>
      Number.isNaN(val) ? 0 : val;

    const totalPutDown =
      safelyConvertToNumber(downPayment) + safelyConvertToNumber(tradeInVal);
    const remainingBalance = basePrice - totalPutDown;

    const monthlyRatesOutput = calcRatesForVaryingMonths(
      remainingBalance,
      creditScoreRate
    );

    setTimeout(() => setIsLoadingCalculations(false), 500);

    setMonthlyRates(monthlyRatesOutput);
    setHasCalculated(true);
  };

  const shouldTransition = width && width < 900;

  const shouldShrink = width && width < 600;

  return (
    <Container ref={ref}>
      <Title>Finance Calculator</Title>
      <Section transition={!!shouldTransition}>
        <InputSection
          transition={!!shouldTransition}
          style={!shouldTransition ? { marginLeft: '1rem' } : {}}
        >
          <SubTitle style={{ marginBottom: '1rem' }}>
            Finance Information
          </SubTitle>
          <TextField
            margin="dense"
            type="number"
            inputProps={{ step: '100' }}
            sx={inputSx}
            InputLabelProps={{ sx: labelSx }}
            error={!!errors.basePrice}
            label="Base Vehicle Price"
            placeholder={
              !!errors.basePrice
                ? 'This Field is Required'
                : 'Enter Base Vehicle Price'
            }
            helperText={!!errors.basePrice && 'This Field is Required'}
            {...register('basePrice', { required: true, valueAsNumber: true })}
          />
          <TextField
            margin="dense"
            type="number"
            inputProps={{ step: '100' }}
            InputLabelProps={{ sx: labelSx }}
            label="Down Payment"
            sx={inputSx}
            placeholder={'Down Payment'}
            {...register('downPayment', {
              required: false,
              valueAsNumber: true,
            })}
          />
          <TextField
            margin="dense"
            type="number"
            inputProps={{ step: '100' }}
            InputLabelProps={{ sx: labelSx }}
            label="Trade-In Value"
            sx={inputSx}
            placeholder={'Trade-In Value'}
            {...register('tradeInVal', {
              required: false,
              valueAsNumber: true,
            })}
          />
        </InputSection>
        <InputSection
          transition={!!shouldTransition}
          style={!!shouldTransition ? { alignItems: 'center' } : {}}
        >
          <SubTitle>Credit Information</SubTitle>
          <CreditScoreSlider onSliderChange={setCreditScoreRate} />
        </InputSection>
      </Section>
      <CalculateButton onClick={handleSubmit(onSubmit)}>
        Calculate
      </CalculateButton>
      {hasCalculated && monthlyRates && (
        <MonthlyCalcsSection>
          <SubTitle style={!!shouldTransition ? {} : { marginLeft: '1rem' }}>
            Estimated Monthly Payments
          </SubTitle>
          {isLoadingCalculations ? (
            <Loader isLoading />
          ) : (
            <MonthlyRatesWrapper>
              {monthlyRates.map((rate, index) => (
                <MonthlyPaymentSection
                  style={!!shouldShrink ? { padding: '1rem' } : {}}
                >
                  <MonthTitle
                    style={!!shouldShrink ? { fontSize: '1rem' } : {}}
                  >
                    {calculatedMonths[index]} Months
                  </MonthTitle>
                  <MonthValue
                    style={!!shouldShrink ? { fontSize: '1rem' } : {}}
                  >
                    ${rate[calculatedMonths[index]]}
                  </MonthValue>
                </MonthlyPaymentSection>
              ))}
            </MonthlyRatesWrapper>
          )}
          <DisclaimerText smallScreen={!!shouldShrink}>
            {financeDisclaimer}
          </DisclaimerText>
        </MonthlyCalcsSection>
      )}
    </Container>
  );
};
