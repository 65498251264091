import React, { FC } from 'react';
import styled from 'styled-components';
import { dWhiteMotorsDarkerRed, softWhite } from '../../constants/colors';
import { H2 } from '../atoms/Headers';

const Wrapper = styled.div`
  margin-top: 1rem;
`;

const Title = styled(H2)`
  margin: 0 0 5px 0;
  font-size: 2rem;
`;

const Container = styled.div`
  border: 2px solid ${dWhiteMotorsDarkerRed};
  border-radius: 10px;
  background-color: ${softWhite};
  padding: 10px;
`;

const NoDocsFoundSpan = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: black;
`;

interface DocumentsSectionProps {}

type Documents = {
  name: string;
};

export const DocumentsSection: FC<DocumentsSectionProps> = () => {
  const documents: Array<Documents> = [];

  return (
    <Wrapper>
      <Title>Documents</Title>
      <Container>
        {documents.length ? (
          documents.map((document, ix) => <div key={ix}>{document.name}</div>)
        ) : (
          <NoDocsFoundSpan>No documents found</NoDocsFoundSpan>
        )}
      </Container>
    </Wrapper>
  );
};
